import React, { useState } from "react";
import { Row, Col, Button, Form, Input, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import Apicalls from "../../services/apicalls";
import "../../assets/loginpage.css";
import logo from "../../assets/lnsgopslogo.png";
import logo1 from "../../assets/greenopslogo.png";
import fileData from "../../services/utils";

let LoginPage = () => {
  const [loginhandler, setLoginHandler] = useState('');
  let Redirect = useNavigate();
  const onFinish = (values) => {
    let body = {
      user_name: values.username,
      user_password: values.password,
    };
    Apicalls.login(body)
      .then((res) => {
        localStorage.setItem("AccessToken", res.data[0]['token']);
        fileData.apicolumndata = res.data[1];
        return Redirect("/landing");
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          setLoginHandler(err.response.data.message);
        } else {
          console.error(err.message);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Row className="login-page-row">
        <Col
          span={16}
          className="info"
          style={{ paddingLeft: "unset", width: "65%" }}
        >
          <img src={logo} style={{ width: "71px", height: "70px" }} />
          <br />
          <br />
          <div
            style={{ display: "grid", background: "#576574", opacity: "0.8" }}
          >
            <div style={{ margin: "auto" }}>
              <h1 style={{ textAlign: "center" }}>EDA Tools</h1>
            </div>
          </div>
        </Col>
        <Col
          span={8}
          style={{
            backgroundImage:
              "linear-gradient(315deg, #c2b6b6 0%, #576574 74%)",
            paddingLeft: "unset",
            width: "35%",
          }}
        >
          <div style={{ marginTop: "38%" }}>
            <h2 style={{ textAlign: "center" }}>Sign in to your account</h2>
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="login-form-main"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <br />
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              {loginhandler && (
                <div style={{ color: "red", textAlign: "center", marginBottom: '10px' }}>
                  {loginhandler}
                </div>
              )}
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <h2 style={{ textAlign: "center", marginLeft: "15%" }}>
              Powered by -{" "}
              <img src={logo1} style={{ width: "158px", height: "38px" }} />
            </h2>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
