import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import {Table,Tag} from 'antd';
const Modalpopup = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData,setTableData] = useState({columns:[],rows:[]});
  useEffect(()=>{
    if(props.name){
      let tabledata = props.value;
      let rows = [];
      let columns = [];
      let newrows = [];
      if(props.name != "pearson" && props.name != "spearman"){
        for(let key in tabledata){
          columns.push({
           title:key,
           dataIndex:key,
           key:key
          });
          newrows.push({[key]:tabledata[key]})
      }
      let refObj = newrows[0];
      let iObj = {};
      for(let refkey in refObj){
         iObj = refObj[refkey]
      }
      Object.values(iObj).forEach((value, index)=> {
        let obj = {};
        newrows.map((ivalue,index1)=>{
           let values = ivalue;
           for(let nkey in values){
            let innervalues = values[nkey];
            Object.values(innervalues).forEach((ivalues,newindex)=>{
              if(newindex == index){
                if(ivalues != null){
                  //obj[nkey] = <Tag color="cyan">{ivalues}</Tag>;
                  if(props.name == "one_hot_encoding" && nkey != "encoded"){
                    obj[nkey] = <Tag color="cyan">{ivalues}</Tag>;
                  }
                  else if(props.name != "one_hot_encoding"){
                    obj[nkey] = <Tag color="cyan">{ivalues}</Tag>;
                  }
                }
                else{
                  obj[nkey] = <Tag color="orange" >No data - received empty value</Tag>;
                }
              }
            })
          }
        });
        rows.push(obj)
    });
    setTableData({columns:columns,rows:rows});
      }
      else{
        setTableData({columns:[{
          dataIndex:'0',
          name:'0',
          key:'0'
        }],rows:[props.value]})
      }
    
      
   }
  },[props.name])
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <span onClick={showModal} style={{fontSize:'12px',marginBottom:'unset',color:'#095cb5',textDecoration:'underline',fontWeight:'bold',cursor:'pointer',marginLeft:'3px'}}>
        Details
      </span>
      <Modal title="Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <Table
                      dataSource={[...tableData.rows]}
                      columns={tableData.columns}
                      className="table-height"
                      bordered={true}
                    />
      </Modal>
    </>
  );
};

export default Modalpopup