import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  DashOutlined,
  DownOutlined,
  HomeOutlined,
  SaveFilled
} from "@ant-design/icons";
import Apicalls from "../services/apicalls";
import Highlighter from "react-highlight-words";
import ModelPlotlyCharts from "./ModelChartHandler";
import Modalpopup from "./modal";
import { Tooltip, Table,message } from 'antd';
import {InfoCircleOutlined }from "@ant-design/icons";

import {  Popover } from 'antd';
import { Card, Layout, Menu, Dropdown, Button, } from "antd";
import { Row, Col } from "antd";
import { Tabs } from "antd";

import React, { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import Headerdata from "./Header";
import Appupload from "./Upload";
import logo from "../assets/lnsgopslogo.png";
import logo1 from "../assets/greenopslogo.png";
import {Modal} from 'antd';


import { Link, useNavigate } from "react-router-dom";
import fileData from "../services/utils";
import TooltipinfoHomepage from "./TooltipHomepage";


// import Uploadhere from './Upload';
const { Header, Sider, Content, Footer } = Layout;
const { TabPane } = Tabs;
const text = <span>Title</span>;
const content = (
  <div>
    <p>Content</p>
    <p>Content</p>
  </div>
);
const buttonWidth = 70;




const Homepage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isPageAccessible, setPage] = useState(false);
  useEffect(() => {
    let localValidation = localStorage.getItem("AccessToken");
    if (localValidation != null && localValidation != undefined) {
      setPage(true);
      fileData.filevalues = "";
      fileData.columnDetails.length =[];
    } else {
      return Redirect("/login");
    }
    savedModellist();
  }, []);
  // function FilterTabs(value) {
  //   if (value == 3) {
      
  //   }
  // }
  let Redirect = useNavigate();
  function MenuOnClick(e) {
    useNavigate("landing");
  }
  function Logout(){
    localStorage.removeItem('AccessToken');
    setTimeout(()=>{
      return Redirect("/login");
    },500);
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableList, setTableList] = useState({columns: [], rows:[]});
  
  const [checkedResponse, setCheckedResponse] = useState({ value: [] });
  const [isStacked, setStacked] = useState("unset");
  const [errHandler, setErrHandler] = React.useState("");
  const [isButtonLoading, ButtonLoader] = useState(false);
  const [linearColumns, SetLinear] = useState({
    dependent: "",
    independent: [],
  });
  const [saveModel,setModel] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    
    setIsModalVisible(isModalVisible => !isModalVisible);
  };

  const handleCancel = () => {
    
    setIsModalVisible(isModalVisible => !isModalVisible);
  };

  const TooltipinfosavedModel = () => (
    <Tooltip title="You can predict the model by loading the model from saved models and uploading New File">
      <span><InfoCircleOutlined /></span>
    </Tooltip>
  );

  function savedModellist () {
  Apicalls.Savemodellist().then(res => {
    let response = res.data; 
    let savedcolumns=[  {
      title: 'Saved Models',
      dataIndex: 'saved_model',
      key: 'saved_model',
    },
    {
      title: 'Load Model',
      dataIndex: 'load_model',
      key: 'load_model',
    },
  ];
    let savedrows=[];
    for(let key in response){
       let innerObj = response[key];
       for(let innerKey in innerObj){
        savedrows.push(
              {
                key: innerObj[innerKey],
                saved_model:innerObj[innerKey],
                 load_model:<Button onClick={()=>{loadSubmit(innerObj[innerKey])}}>Load Model</Button>
              }
          )
       }
       setTableList({columns:savedcolumns, rows: savedrows})
    }

  })
  .catch((err) => {
    message.error(err.message);
    setCheckedResponse({
      value: [
        {
          name: "Linear Regression request",
          color: "red",
          message: err.message,
        },
      ],
    });
  });
}

function loadSubmit(savedmodelname){
  Apicalls.LoadModel(savedmodelname.slice(0,-4)).then(res=>{
    let response = res.data
    fileData.loadmodelresponse=response;
    fileData.loadmodelname= savedmodelname.slice(0,-4)
    message.success("Model Loaded successfully");
     setTimeout(() => {
       setErrHandler("");
     }, 5000);
   })
   .catch((err) => {
     setErrHandler(err.message);
     setTimeout(() => {
       setErrHandler("");
     }, 5000);
     
   });
   console.log(fileData.loadmodelresponse)
 }

  const menu = (
    <Menu
      items={[
        // {
        //   label:(
        //     <a onClick={showModal}> Saved projects
        //     </a>
        //   ),
        
        // },
        {
          label: (
           
            <a
            onClick={Logout}
           
            >
              Logout
            </a>
          ),
          key: "0",
        },
      ]}
    />
  );
  return (
    <>
      {isPageAccessible && (
        <Layout>
          <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
        <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ position: "relative" }}
      >
        <div className="logo">
          <span className="lnslogo">
            <img src={logo} alt="" style={{ width: "60px" }} />
          </span>
        </div>

        <Menu
          // theme="dark"
          mode="inline"
          style={{
            backgroundColor: "#171717",
            color: "#fff",
            padding: "6%",
            paddingLeft: "4%",
          }}
          defaultSelectedKeys={["general-eda"]}
          items={[
            {
              key:"landing",
              icon:<HomeOutlined />,
              label:"Homepage",
            },
          
          ]}
          onClick={MenuOnClick}
        />
        <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
          <div style={{ width: "100%", display: "grid", marginBottom: "20%" }}>
            <Button
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              className="trigger"
              style={{ margin: "auto" }}
            >
              {collapsed == true && <MenuUnfoldOutlined />}
              {collapsed == false && <MenuFoldOutlined />}
            </Button>
          </div>
        </div>
      </Sider>
          <Layout className="site-layout">
            <Header
              style={{
                padding: 0,
                height: "70px",
                backgroundColor: "#171717",
              }}
            >
              <Row>
            <Col span={24}>
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <div style={{ width: "100%", justifyContent: "center" }}>
                  <h1 style={{ textAlign: "center" }}>
                    {" "}
                    EDA TOOLS 4.0 - Powered by{" "}
                    <span className="lnsgopslogo">
                      <img src={logo1} alt="" style={{ width: "100px" }} />
                    </span>
                  </h1>
                </div>
                <div
                  style={{
                    width: "20%",
                    maxWidth: "max-content",
                    justifyContent: "right",
                  }}
                >
                  <Dropdown overlay={menu} style={{ float: "right" }}>
                    <Button
                      onClick={(e) => e.preventDefault()}
                      style={{ color: "white" }}
                    >
                      <UserOutlined />
                      Admin
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>

             
              
            </Header>
            <Content
            
              style={{
                margin: "10px ",
                height: "83.3vh",
               
              }}
            >
              <Row className="dashboard-row">
            <Col span={10} className="dashboard-col">
              <Card
                className="card"
                size="small"
                style={{ height: "100%", minHeight: "583px", overflow: "auto", }}
              >
                 <Tabs defaultActiveKey="1" type="card"
                  // onChange={FilterTabs}
                  >
                  {/* <TabPane 
                  tab={
                      <span>
                        <SaveFilled />
                        Saved Tables
                      </span>
                    }
                    key="1"
                    className="table-pan"
                  >
                    <p style={{ marginBottom: "5px" }}>Saved Tables </p>
                  </TabPane> */}
                  <TabPane
                    tab={
                      <span>
                         <SaveFilled />
                        Saved Models <TooltipinfosavedModel/>
                      </span>
                    }
                    key="1"
                    className="table-pan"
                  >
                   {/* {tableList.columns.length == 0 && (
                      <div className="model-placeholder">
                        <div style={{ margin: "auto" }}>
                          <p>You have no Saved Models<br/>Build and Save the New Models</p>
                        </div>
                      </div>
                    )} */}
                    {/* {tableList.columns.length != 0 && (
                      <>
                        <Row>
                          <Col
                            span={24}
                            id="model-chart-pie"
                            style={{ padding: "2%" }}
                          >
                            <div className="ant-table-content ">
                        <table style={{ width: "100%" }}>
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell">List of Saved Models</th>
                              <th className="ant-table-cell">Load Model</th>
                            </tr>
                          </thead>
                          <tr>
                          <tbody className="ant-table-tbody">
                           <td>Model 1</td>
                           <td><Button onClick={loadModel}>Load Model</Button></td>
                           <td>Model 1</td>
                           <td><Button>Load Model</Button></td>
                           <td>Model 1</td>
                           <td><Button>Load Model</Button></td>
                          </tbody>
                          </tr>
                        </table>
                      </div>
                          </Col>
                        </Row>
                      
                      </>
                    )} */}
                     




                    
                    <Table columns={tableList.columns} dataSource={tableList.rows}/>
      
                  </TabPane>
                  
                </Tabs>
              
              </Card>
            </Col>
            <Col span={14} className="dashboard-col" style={{minWidth:'63%'}}>
              <Card
                size="small"
                className="card-default-dt"
  
                style={{ height: "100%", minHeight: "583px", overflow: "auto",display: "flex", flexDirection:"column", justifyContent:"center" }}
              >
             


                 <div  style={{ display:"grid", height: "100%"}}>
                
                <div style={{ margin: "auto" }}>
                  <h1
                    style={{
                      color: "white",
                      fontSize: "bold",
                      textAlign: "center",
                      marginBottom: "unset",
                    }}
                  >
                    Automated EDA
                  </h1>
                  <p style={{ color: "white", textAlign: "center" }}>
                    Exploratory Data Analysis <TooltipinfoHomepage/>
                  </p>
                  <Appupload />
                  {/* <p style={{ color: "#141414", fontSize: "12px", textAlign: "center" }}>Create New model By Uploading New File<br/>or<br/> You can predict the model by loading the model from saved models and uploading New File</p> */}

                </div>
              </div>
              </Card>
            </Col>
          </Row>
             
            </Content>
            <Footer
              // className="site-layout-background"
              style={{
                padding: 0,
                maxHeight: "max-content",
                backgroundColor: "#171717",
                display: "grid",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  margin: "auto",
                  fontSize: "14px",
                }}
              >
                All Rights Reserved | © 2024 LivNSense ® Technologies Pvt Ltd
              </h1>
            </Footer>
          </Layout>
        </Layout>
      )}
    </>
  );
};


<a href="https://www.flaticon.com/free-icons/save" title="save icons">Save icons created by Freepik - Flaticon</a>

export default Homepage;
