import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import Plot from "react-plotly.js";

let PlotlyCharts = (props) => {
  const [chartType, setType] = useState("");
  const [chartXaxis, setx] = useState([]);
  const [chartYaxis, sety] = useState([]);
  const [isLoaded, Loading] = useState(true);
  const [chartWidth, setWidth] = useState({ width: "", height: "" });
  useEffect(() => {
    Loading(true);
    setType(() => props.type);
    let chartData = props.data;
    let chartRows = [];
    if (props.type != "heatmap" && props.type != "correlation") {
      props.yAxis.map((value) => {
        let yvalues = [];
        chartData.map((values) => {
          yvalues.push(values[value]);
        });
        chartRows.push({ type: props.type, y: yvalues });
      });
    }
    if (props.type == "heatmap") {
      console.log(props,props.yAxis)
      let xColumn = [];
      let yColumn = [];
      let zColumn = [];
      props.yAxis.map((value) => {
        xColumn.push(value);
        yColumn.push(value);
        let innerarr = [];
        chartData.map((innervalue) => {
          innerarr.push(innervalue[value]);
        });
        zColumn.push(innerarr);
      });
      chartRows.push({ type: props.type, z: zColumn, y: xColumn, x: yColumn });
    }
    if (props.type == "correlation") {
      let xColumn = [];
      let yColumn = [];
      let zColumn = [];
      props.yAxis.map((value) => {
        xColumn.push(value);
        yColumn.push(value);
        let innerarr = [];
        chartData.map((innervalue) => {
          innerarr.push(innervalue[value]);
        });
        zColumn.push(innerarr);
      });
      chartRows.push({
        type: "heatmap",
        z: zColumn,
        dx: 1,
        dy: 1,
        x0: 0,
        y0: 0,
        scl: [
          ["0", "rgb(0,0,255)"],
          ["0.1", "rgb(51,153,255)"],
          ["0.2", "rgb(102,204,255)"],
          ["0.3", "rgb(153,204,255)"],
          ["0.4", "rgb(204,204,255)"],
          ["0.5", "rgb(255,255,255)"],
          ["0.6", "rgb(255,204,255)"],
          ["0.7", "rgb(255,153,255)"],
          ["0.8", "rgb(255,102,204)"],
          ["0.9", "rgb(255,102,102)"],
          ["1", "rgb(255,0,0)"],
        ],
        name: "trace 0",
        zmax: 1,
        zmin: -0.25987153430497417,
        zsrc: "plotly.js:12:",
        zauto: true,
      });
    }
    let width = document.getElementById("plotly-col");
    setWidth({ width: width.offsetWidth, height: width.offsetHeight });
    sety(() => chartRows);
    // console.log(chartRows);
    Loading(false);
  }, [props.type, props.xaxis, props.yAxis, props.data, props.stackmode]);

  return (
    <Spin spinning={isLoaded} style={{ height: "100%" }}>
      {props.type != "correlation" && (
        <Plot
          data={chartYaxis}
          layout={{
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            barmode: props.stackmode,
            height: chartWidth.height - 50,
            width: chartWidth.width - 50,
            margin: { t: 15, b: 35, l: 35, r: 15 },
            yaxis: {
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              gridcolor: "#303030",
            },
            xaxis: {
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              gridcolor: "#303030",
            },
            legend: {
              font: {
                color: "rgba(255, 255, 255, 0.85)",
                family: "Lato",
                size: 12,
              },
            },
          }}
          config={{ displayModeBar: true }}
          style={{ margin: "auto", marginLeft: "2%" }}
        />
      )}
      {props.type == "correlation" && (
        <Plot
          data={chartYaxis}
          layout={{
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            barmode: props.stackmode,
            height: chartWidth.height,
            width: chartWidth.width,
            margin: { t: 15, b: 35, l: 35, r: 15 },
            xaxis: {
              type: "linear",
              dtick: 2,
              range: [-0.5, 19.5],
              tick0: 0,
              ticks: "",
              title: "",
              anchor: "y",
              domain: [0, 1],
              mirror: true,
              nticks: 13,
              ticklen: 5,
              autotick: true,
              position: 0,
              showgrid: true,
              showline: true,
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              gridcolor: "#303030",
              zeroline: true,
              autorange: true,
              gridcolor: "#303030",
              gridwidth: 1,
              linecolor: "#303030",
              linewidth: 8,
              rangemode: "normal",
              tickangle: 0,
              tickcolor: "#303030",
              tickwidth: 1,
              titlefont: {
                size: 0,
                color: "",
                family: "",
              },
              overlaying: false,
              showexponent: "all",
              zerolinecolor: "#303030",
              zerolinewidth: 1,
              exponentformat: "e",
              showticklabels: true,
            },
            yaxis: {
              type: "linear",
              dtick: 2,
              range: [-0.5, 19.5],
              tick0: 0,
              ticks: "",
              title: "",
              anchor: "x",
              domain: [0, 1],
              mirror: true,
              nticks: 13,
              ticklen: 5,
              autotick: true,
              position: 0,
              showgrid: true,
              showline: true,
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              zeroline: true,
              autorange: true,
              gridcolor: "#ddd",
              gridwidth: 1,
              linecolor: "rgb(207, 226, 243)",
              linewidth: 8,
              rangemode: "normal",
              tickangle: 0,
              tickcolor: "#303030",
              tickwidth: 1,
              titlefont: {
                size: 0,
                color: "",
                family: "",
              },
              overlaying: false,
              showexponent: "all",
              zerolinecolor: "#303030",
              zerolinewidth: 1,
              exponentformat: "e",
              showticklabels: true,
            },
            bargap: 0.2,
            height: 630,
            width: 840,
            legend: {
              font: {
                size: 0,
                color: "",
                family: "",
              },
              bgcolor: "#fff",
              traceorder: "normal",
              bordercolor: "#000",
              borderwidth: 1,
            },
            margin: {
              b: 60,
              l: 70,
              r: 200,
              t: 60,
              pad: 2,
              autoexpand: true,
            },
            barmode: "stack",
            boxmode: "overlay",
            autosize: false,
            dragmode: "zoom",
            hovermode: "x",
            titlefont: {
              size: 0,
              color: "",
              family: "",
            },
            legend: {
              font: {
                color: "rgba(255, 255, 255, 0.85)",
                family: "Lato",
                size: 12,
              },
            },
          }}
          config={{ displayModeBar: true }}
          style={{ margin: "auto", marginLeft: "2%" }}
        />
      )}
    </Spin>
  );
};

export default PlotlyCharts;
