import React from "react";
import MainLayout from "./layout";
import "./App.css";


function App() {
  return (
    <div className="App">
     <MainLayout/>
     
    </div>
  );
}

export default App;