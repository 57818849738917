import React, { useEffect, useState, useRef } from "react";
import {
  UploadOutlined,
  UserOutlined,
  FilterOutlined,
  LoadingOutlined,
  InsertRowRightOutlined,
  CloseSquareOutlined,
  CheckSquareOutlined,
  FileAddOutlined,
  TableOutlined,
  UnorderedListOutlined,
  EditOutlined,
  ControlOutlined,
  LayoutOutlined,
  BuildOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  SearchOutlined,
  LineChartOutlined,
  SortAscendingOutlined,
  CommentOutlined,
  FastForwardOutlined,
  FastBackwardOutlined,
  DatabaseOutlined,
  HddOutlined,
  HomeOutlined
} from "@ant-design/icons";
import {
  Card,
  Table,
  Button,
  message,
  Tag,
  Input,
  Checkbox,
  InputNumber,
  Timeline,
  Empty,
  Dropdown,
  Space,
  Layout,
  Menu,
  Tabs,
  Select,
  Row,
  Col,
  Switch,
  Radio,
} from "antd";
import logo from "../assets/lnsgopslogo.png";
import logo1 from "../assets/greenopslogo.png";
import fileData from "../services/utils";
import Apicalls from "../services/apicalls";
import Highlighter from "react-highlight-words";

import { useNavigate } from "react-router-dom";
import PlotlyCharts from "./ChartHandler";
import Modalpopup from "./modal";
import Tooltipchisquare from "./TooltipChisquare";
import Tooltiplabel from "./TooltipLabel";
import TooltipOnehot from "./TooltipOnehot";
import Tooltipoutlier from "./Tooltipoutlier";
import Tooltipnormalize from "./Tooltipnormalize";
import Tooltipstandardize from "./Tooltipstandardize";
import Tooltipmutual from "./Tooltipmutual";
import {Modal} from 'antd';
import PcaIcaChartHandler from "./PcaIcaChartHandler";
const { TabPane } = Tabs;
const { Option } = Select;
const { Header, Sider, Content, Footer } = Layout;
const { Search } = Input;
let GeneralEda = () => {
  let navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [tableValues, setTableValues] = useState({ columns: [], rows: [] });
  const [copied, copiedValues] = useState({ columns: [], rows: [] });
  const [isLoading, TableLoad] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isDisplayTable, DisplayTable] = useState(true);
  const [tableList, setTableList] = useState({columns: [], rows:[]});
  const [tableListica,setTableListica] = useState({columns: [], rows:[]});
  const [chartXaxis, xAxis] = useState([]);
  const [chartYaxis, yAxis] = useState([]);
  const [chartType, setType] = useState("");
  const [columnDataTypes, setColumns] = useState({ columns: [] });
  const [checkedFilters, CheckedFilters] = useState("");
  const [checkedResponse, setCheckedResponse] = useState({ value: [] });
  const [isStacked, setStacked] = useState("unset");
  const [tabfilteroptions, settabfilter] = useState({
    chi_square_test: [],
    label_encoding: [],
    one_hot_encoding: [],
  });
  const [chiSquareColumns, setChiSquareColumns] = useState({
    x_columns: "",
    y_columns: "",
  });
  const [pearsonColumns, setpearsonColumns] = useState({
    x_columns: "",
    y_columns: "",
  });
  const [spearmanColumns, setspearmanColumns] = useState({
    x_columns: "",
    y_columns: "",
  })
  const [tabfilteroptions2, settabfilter2] = useState({
    outlier: "",
    normalize: false,
    standardize: false,
    mutual_features: [],
    correlation: [],
    pearson:[],
    spearman:[],
    DropTarget:[],
    pca: [],
    ica: [],
  });

  const [tabfilteroptions3, settabfilter3]= useState({
    DropTarget:[],
    pca: [],
    ica:[]
  
  });
  const [isButtonLoading, ButtonLoader] = useState(false);
  const [outlinerDefault, DefaultoutLiner] = useState([]);
  const [stateNorm, setNorm] = useState("");
  const [submitToggle, isSubmitDisabled] = useState(true);
  useEffect(() => {
  //   if (fileData.filevalues == "") {
  //     return navigate("/landing");
  //   } else {
  //     updateTableColumns();
  //   }
  // }, []);
  let token = localStorage.getItem('AccessToken');
  if (fileData.filevalues == "") {
    return navigate("/landing");
  }
  else if(token == undefined || token == null){
    return navigate("/login")
  } else {
    updateTableColumns();
  }
}, []);
  function updateTableColumns() {
    TableLoad(true);
    Apicalls.getDataInfo()
      .then((res) => {
        let stringifyResponse = JSON.stringify(res.data);
        let response_parse = [];
        let response = [];
        if(stringifyResponse.includes('NaN')){
          stringifyResponse = stringifyResponse.replaceAll(`NaN`,`null`);
          response_parse = JSON.parse(stringifyResponse);
          response = JSON.parse(response_parse);

        }
        else{
          response = JSON.parse(stringifyResponse);
        }
        let columnDetails = response[0][0];
        let rowDetails = response[1];
        columnDetails = columnDetails.replaceAll(`dtype(`, "");
        //columnDetails = columnDetails.replaceAll(`)`,'');
        columnDetails = columnDetails.replaceAll(`float64')`, `float64'`);
        columnDetails = columnDetails.replaceAll(`int64')`, `int64'`);
        columnDetails = columnDetails.replaceAll(`<M8[ns]')`, `<M8[ns]'`);
        columnDetails = columnDetails.replaceAll(`string')`, `string'`);
        columnDetails = columnDetails.replaceAll(`O')`, `O'`);
        columnDetails = columnDetails.replaceAll(`'`, `"`);
        let defaultKey = "";
        let parsedColumnDetails = JSON.parse(columnDetails);
        let acolumndetails = [];
        let acolumntypes = [];
        for (let key in parsedColumnDetails) {
          acolumndetails.push({
            title: `${key}`,
            dataIndex: `${key}`,
          });
          acolumntypes.push({
            title: key,
            dataIndex: parsedColumnDetails[key],
          });
        }
        // function addRowValues() {
        //   innerCount = innerCount + 1;
        //   let obj = {};
        //   if (innerCount != getLength) {
        //     fileData.columnDetails.map((value) => {
        //       obj[value.title] = rowDetails[value.title][innerCount];
        //     });
        //     rowFinalValues.push(obj);
        //     return addRowValues();
        //   }
        // }
        //console.log(fileData.columnDetails)
        fileData.columnTypes = acolumntypes;
        fileData.columnDetails = acolumndetails;
        defaultKey = fileData.columnDetails[0].title;
        let innerCount = -1;
        let getLength = Object.keys(rowDetails[defaultKey]).length - 1;
        let rowFinalValues = [];
        for(let x=0; x <= getLength -1; x++){
          let obj = {};
          fileData.columnDetails.map((value) => {
            
            if(rowDetails[value.title][x] !== undefined || rowDetails[value.title][x] === null){
              if(rowDetails[value.title][x] === null ){
                obj[value.title] = <Tag color="orange" >Empty value</Tag>;
            }
            else{
              obj[value.title] = rowDetails[value.title][x];
            }
                
            }
            
          });
          let isObjectInjectable = Object.keys(obj).length;
          if(isObjectInjectable != 0){
            rowFinalValues.push(obj)
          }
        }
        //addRowValues();
        fileData.rowDetails = rowFinalValues;
      
        setTableValues({ columns: fileData.columnDetails, rows: rowFinalValues });
        copiedValues({ columns: fileData.columnDetails, rows: rowFinalValues });
        setColumns({ columns: fileData.columnTypes });
        let defaultvalues = fileData.columnTypes.map((value) => {
          return `{"x_columns":"${value.title}"}`;
        });
        DefaultoutLiner(defaultvalues);
        TableLoad(false);
      })
      .catch((err) => {
        message.error(err.message);
        setTableValues({ columns: [], rows: [] });
        copiedValues({ columns: [], rows: [] });
        setColumns({ columns: [] });
        TableLoad(false);
      });
    // }
    // else{
    //   setTableValues({ columns: [], rows: [] });
    //      setSummaryValues({columns: [], rows: []});
    //      copiedValues({ columns: [], rows: [] });
    //      TableLoad(false);
    // }
  }
  function MenuOnClick(e) {
    navigate("/" + e.key);
  }
  // function Logout(){
  //   navigate("/login")
  // }
  function Logout(){
    localStorage.removeItem('AccessToken');
    setTimeout(()=>{
      return navigate("/login");
    },500);
  }
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    // console.log(tabfilteroptions3)
    // pcaApiCall();
    // icaApiCall();
    if(tabfilteroptions3.pca > 1){
      pcaApiCall();
    }
    else{
      setTableList({columns:[],rows:[]});
    }
    if(tabfilteroptions3.ica > 1){
      icaApiCall();
    }
    else{
      setTableListica({columns:[],rows:[]});
    }
  };
  const handleOk = () => {

    setIsModalVisible(isModalVisible => !isModalVisible);
  };

  const handleCancel = () => {
    // console.log("dsfa")
    setIsModalVisible(isModalVisible => !isModalVisible);
  };
  const menu = (
    <Menu
      items={[
        {
          label:(
            <a onClick={showModal}> Saved projects
            </a>
          ),
        
        },
        {
          label: (
            <a
              onClick={Logout}
              
            >
              Logout
            </a>
          ),
          key: "0",
        },
      ]}
    />
  );
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space></Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  var chartTypes = ["bar", "heatmap", "line", "correlation"];
  function FilterTabs(value) {
    if (value == 1) {
      DisplayTable(true);
    } else if (value == 2) {
      DisplayTable(true);
    } else {
      DisplayTable(false);
    }
  }
  function setChartOptions(value, type) {
    if (type == "type") {
      setType(value);
    } else if (type == "xaxis") {
      xAxis(value);
    } else {
      yAxis(value);
    }
  }
  function stackModeHandler(e) {
    if (e.target.checked == true) {
      setStacked("stack");
    } else {
      setStacked("unset");
    }
  }
  function outlierOptions(value) {
    let e = [];
    value.map((values) => {
      e.push(JSON.parse(values));
    });
  }
  function squareTestX(e) {
    if (e != undefined){
      setChiSquareColumns({
      x_columns: e,
      y_columns: chiSquareColumns.y_columns,
    });
  }
    else{
      setChiSquareColumns({
        x_columns: [],
        y_columns: chiSquareColumns.y_columns,
      });
    }
  }
  function squareTestY(e) {
    if (e != undefined){
      setChiSquareColumns({
      x_columns: chiSquareColumns.x_columns,
      y_columns: e,
    });
  }
    else{
      setChiSquareColumns({
        x_columns: chiSquareColumns.x_columns,
        y_columns: [],
      });
    }
  }

  function pearsonX(e){
    if(e != undefined){
    setpearsonColumns({
      x_columns: e,
      y_columns: pearsonColumns.y_columns,
    });
  }
  else{
    setpearsonColumns({
      x_columns: [],
      y_columns: pearsonColumns.y_columns,
    });
  }
  }
  function pearsonY(e){
    if(e != undefined){
    setpearsonColumns({
      x_columns: pearsonColumns.x_columns,
      y_columns: e,
    });
  }else{
    setpearsonColumns({
      x_columns: pearsonColumns.x_columns,
      y_columns: [],
    });
  }
  }
  function spearmanX(e){
    if(e != undefined){
    setspearmanColumns({
      x_columns: e,
      y_columns: spearmanColumns.y_columns,
    });
  }else{

    setspearmanColumns({
      x_columns: e,
      y_columns: spearmanColumns.y_columns,
    });}
  }
  function spearmanY(e){
    if(e != undefined){
    setspearmanColumns({
      x_columns: spearmanColumns.x_columns,
      y_columns: e,
    });
  }else{
    setspearmanColumns({
      x_columns: spearmanColumns.x_columns,
      y_columns: e,
    });}
  }
  function labelEncoding(value) {
    let e = [];
    value.map((values) => {
      e.push(JSON.parse(values));
    });
    settabfilter({
      chi_square_test: tabfilteroptions.chi_square_test,
      label_encoding: e,
      one_hot_encoding: tabfilteroptions.one_hot_encoding,
    });
  }
  function oneHotEncoding(value) {
    let e = [];
    value.map((values) => {
      e.push(JSON.parse(values));
    });
    settabfilter({
      chi_square_test: tabfilteroptions.chi_square_test,
      label_encoding: tabfilteroptions.label_encoding,
      one_hot_encoding: e,
    });
  }
  function Normalize(value) {
    let e = [];
    value.map((values) => {
      e.push(JSON.parse(values));
    });
    settabfilter2({
      outlier: tabfilteroptions2.outlier,
      normalize: e,
      standardize: tabfilteroptions2.standardize,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: tabfilteroptions2.correlation,
      DropTarget: tabfilteroptions2.DropTarget,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
    });
  }
  function Standardize(value) {
    let e = [];
    value.map((values) => {
      e.push(JSON.parse(values));
    });
    settabfilter2({
      outlier: tabfilteroptions2.outlier,
      normalize: tabfilteroptions2.normalize,
      standardize: e,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: tabfilteroptions2.correlation,
      DropTarget: tabfilteroptions2.DropTarget,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
      pearson:[],
      spearman:[],
    });
  }
  function Mutual(e) {
    if(e != undefined){
      settabfilter2({
        outlier: tabfilteroptions2.outlier,
        normalize: tabfilteroptions2.normalize,
        standardize: tabfilteroptions2.standardize,
        mutual_features: [{ column_name: e }],
        correlation: tabfilteroptions2.correlation,
      DropTarget: tabfilteroptions2.DropTarget,
        pca: tabfilteroptions2.pca,
        ica: tabfilteroptions2.ica,
        pearson:[],
        spearman:[],
      });
    }
    else{
      settabfilter2({
        outlier: tabfilteroptions2.outlier,
        normalize: tabfilteroptions2.normalize,
        standardize: tabfilteroptions2.standardize,
        mutual_features: [],
        correlation: tabfilteroptions2.correlation,
      DropTarget: tabfilteroptions2.DropTarget,
        pca: tabfilteroptions2.pca,
        ica: tabfilteroptions2.ica,
        pearson:[],
        spearman:[],
      });
    }
  }
  function Relation(e) {
    if(e != undefined){
    settabfilter2({
      outlier: tabfilteroptions2.outlier,
      normalize: tabfilteroptions2.normalize,
      standardize: tabfilteroptions2.standardize,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: [{ column_name: e }],
      DropTarget: tabfilteroptions2.DropTarget,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
      pearson:[],
      spearman:[],
    });
  }else{
    settabfilter2({
      outlier: tabfilteroptions2.outlier,
      normalize: tabfilteroptions2.normalize,
      standardize: tabfilteroptions2.standardize,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: [],
      DropTarget: tabfilteroptions2.DropTarget,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
      pearson:[],
      spearman:[],
    });

  }}
  function PcaIca(e) {
    if(e != undefined){
    settabfilter3({
      outlier: tabfilteroptions3.outlier,
      normalize: tabfilteroptions3.normalize,
      standardize: tabfilteroptions3.standardize,
      mutual_features: tabfilteroptions3.mutual_features,
      correlation: tabfilteroptions3.correlation,
      DropTarget: e ,
      pca: tabfilteroptions3.pca,
      ica: tabfilteroptions3.ica,
      pearson:[],
      spearman:[],
    });
  }else{
    settabfilter3({
      outlier: tabfilteroptions3.outlier,
      normalize: tabfilteroptions3.normalize,
      standardize: tabfilteroptions3.standardize,
      mutual_features: tabfilteroptions3.mutual_features,
      correlation: tabfilteroptions3.correlation,
      DropTarget: [],
      pca: tabfilteroptions3.pca,
      ica: tabfilteroptions3.ica,
      pearson:[],
      spearman:[],
    });

  }}
  function Pca(e) {
    if (e != undefined){
      settabfilter3({
      outlier: tabfilteroptions3.outlier,
      normalize: tabfilteroptions3.normalize,
      standardize: tabfilteroptions3.standardize,
      mutual_features: tabfilteroptions3.mutual_features,
      correlation: tabfilteroptions3.correlation,
      DropTarget: tabfilteroptions3.DropTarget,
      pca: e,
      ica: tabfilteroptions3.ica,
      pearson:[],
      spearman:[],
    });
  }
  else{
    settabfilter3({
      outlier: tabfilteroptions3.outlier,
      normalize: tabfilteroptions3.normalize,
      standardize: tabfilteroptions3.standardize,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: tabfilteroptions3.correlation,
      DropTarget: tabfilteroptions3.DropTarget,
      pca: [],
      ica: tabfilteroptions3.ica,
      pearson:tabfilteroptions3.pearson,
      spearman:tabfilteroptions3.spearman,
  });
}}

  function Ica(e) {
    if(e != undefined){
    settabfilter3({
      outlier: tabfilteroptions3.outlier,
      normalize: tabfilteroptions3.normalize,
      standardize: tabfilteroptions3.standardize,
      mutual_features: tabfilteroptions3.mutual_features,
      correlation: tabfilteroptions3.correlation,
      DropTarget: tabfilteroptions3.DropTarget,
      pca: tabfilteroptions3.pca,
      ica: e,
      pearson:[],
      spearman:[],
    });
  }
    else{
      settabfilter3({
        outlier: tabfilteroptions3.outlier,
        normalize: tabfilteroptions3.normalize,
        standardize: tabfilteroptions3.standardize,
        mutual_features: tabfilteroptions3.mutual_features,
        correlation: tabfilteroptions3.correlation,
      DropTarget: tabfilteroptions3.DropTarget,
        pca: tabfilteroptions3.pca,
        ica: [],
        pearson:tabfilteroptions3.pearson,
      spearman:tabfilteroptions3.spearman,
    });
  }}


 
  function toPlainString(num) {
    return (''+ +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function(a,b,c,d,e) {
        return e < 0
          ? b + '0.' + Array(1-e-c.length).join(0) + c + d
          : b + c + d + Array(e-d.length+1).join(0);
      });
  }
function pcaApiCall(){
    let postbody = {
     column_name: tabfilteroptions3.DropTarget,
     no_of_components: tabfilteroptions3.pca
    };
  let listofvalues = [];
  Apicalls.pcacalls(postbody).then(res =>{
    let response= res.data;
    let savedcolumns=[];
    let objectLength = 0;
    for(let key in response){
       savedcolumns.push(key)
       objectLength = Object.keys(response[key]).length;
    }
    let newrows = [];
    for(let x=0;x < objectLength; x++){
        let drowvalues = savedcolumns.map(value=>{
            let innerObj = response[value];
            return innerObj[x];
        });
        newrows.push(drowvalues);
    }
    setTableList({columns:savedcolumns, rows: newrows});
    listofvalues.push({
      name:'PCA/ICA Drop Target',
      message: 'filter applied successfully',
      color:'green',
      data:'showmodal'
    });
    setIsModalVisible(true);
  })
  .catch((err) => {
      listofvalues.push({
        name:'PCA/ICA Drop Target',
        message:err.message,
        color:'red',
        data:'showmodal'
      })
  });
  setCheckedResponse({value:listofvalues})
}
function icaApiCall(){

  let postbody = {
   column_name: tabfilteroptions3.DropTarget,
   no_of_components: tabfilteroptions3.ica
  };
  let listofvalues = [];
Apicalls.icacalls(postbody).then(res =>{
  let response= res.data;
    let savedcolumns=[];
    let objectLength = 0;
    for(let key in response){
       savedcolumns.push(key);
       objectLength = Object.keys(response[key]).length;
    }
    let newrows = [];
    for(let x=0;x < objectLength; x++){
        let drowvalues = savedcolumns.map(value=>{
            let innerObj = response[value];
            return innerObj[x];
        });
        newrows.push(drowvalues);
    }
    setTableListica({columns:savedcolumns, rows: newrows});
    setIsModalVisible(true);
    listofvalues.push({
       name:'PCA/ICA Drop Target',
       message:'filter applied successfully',
       color:'green',
       data:'showmodal'
    });
})
.catch((err) => {
  listofvalues.push({
     name:'PCA/ICA Drop Target',
     message:err.message,
     color:'red',
     data:'showmodal'
  });
  setCheckedResponse({value:listofvalues});
  setIsModalVisible(false);
});

}
  

  let batchWiseCall = () => {
    let setOfOptions = tabfilteroptions;
    let listOfKeys = [];
    let listofvalues = [];
    ButtonLoader(true);
    for(let key in setOfOptions){
      listOfKeys.push(key);
    }
    let innercount = 0;
  
    function batchCall(key){
       if(innercount <= listOfKeys.length && key != undefined){
        if (setOfOptions[key].length != 0 && key != "chi_square_test") {
          let postbody = setOfOptions[key];
          Apicalls.encodingcalls(key, postbody)
            .then((response) => {
              ButtonLoader(false);
              let values = response.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: values,
              });
              updateTableColumns();
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        } 
        else if (
          key == "chi_square_test" &&
          chiSquareColumns.x_columns != "" &&
          chiSquareColumns.y_columns != ""
        ) {
          let postbody = [
            {
              x_columns: chiSquareColumns.x_columns,
              y_columns: chiSquareColumns.y_columns,
            },
          ];
          Apicalls.encodingcalls(key, postbody)
            .then((response) => {
              ButtonLoader(false);
              let values = response.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: values,
              });
              updateTableColumns();
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        }
        else{
          innercount = innercount +1;
          batchCall(listOfKeys[innercount]);
        }
       }
       else{
        setCheckedResponse({
          value: listofvalues.map((value) => {
            return value;
          }),
        });
       }
    }
    batchCall(listOfKeys[innercount])

    setCheckedResponse({
      value: listofvalues.map((value) => {
        return value;
      }),
    });
    setTimeout(() => {
      ButtonLoader(false);
      setCheckedResponse({
        
        value: listofvalues.map((value) => {
          return value;
        }),
      });
    }, 2000);
  };

  let batchWiseCall3 = () => {
    let setOfOptions = tabfilteroptions;
    let listOfKeys = [];
    let listofvalues = [];
    ButtonLoader(true);
    for(let key in setOfOptions){
      listOfKeys.push(key);
    }
    let innercount = 0;
  
    function batchCall(key){
       if(innercount <= listOfKeys.length && key != undefined){
        // if (setOfOptions[key].length != 0 && key != "chi_square_test") {
        //   let postbody = setOfOptions[key];
        //   Apicalls.encodingcalls(key, postbody)
        //     .then((response) => {
        //       ButtonLoader(false);
        //       let values = response.data;
        //       listofvalues.push({
        //         name: key,
        //         message: "Updated Successfully",
        //         color: "green",
        //         data: values,
        //       });
        //       updateTableColumns();
        //       innercount = innercount +1;
        //       batchCall(listOfKeys[innercount]);
        //     })
        //     .catch((err) => {
        //       ButtonLoader(false);
        //       listofvalues.push({
        //         name: key,
        //         message: err.message,
        //         color: "red",
        //         data: false,
        //       });
        //       innercount = innercount +1;
        //       batchCall(listOfKeys[innercount]);
        //     });
        // } 
         if (
          key == "chi_square_test" &&
          chiSquareColumns.x_columns != "" &&
          chiSquareColumns.y_columns != ""
        ) {
          let postbody = [
            {
              x_columns: chiSquareColumns.x_columns,
              y_columns: chiSquareColumns.y_columns,
            },
          ];
          Apicalls.encodingcalls(key, postbody)
            .then((response) => {
              ButtonLoader(false);
              let values = response.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: values,
              });
              updateTableColumns();
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        }
        else{
          innercount = innercount +1;
          batchCall(listOfKeys[innercount]);
        }
       }
       else{
        setCheckedResponse({
          value: listofvalues.map((value) => {
            return value;
          }),
        });
       }
    }
    batchCall(listOfKeys[innercount])
    setCheckedResponse({
      value: listofvalues.map((value) => {
        return value;
      }),
    });
    setTimeout(() => {
      ButtonLoader(false);
      setCheckedResponse({
        
        value: listofvalues.map((value) => {
          return value;
        }),
      });
    }, 2000);
  };

  let batchWiseCall2 = () => {
    ButtonLoader(true);
    let setOfOptions = tabfilteroptions2;
    let listOfKeys = [];
    let listofvalues = [];
    
    for(let key in setOfOptions){
      listOfKeys.push(key);
    }
    let innercount = 0;
    function batchCall(key){
      if(innercount <= listOfKeys.length && key != undefined){
        if (
          setOfOptions[key].length != 0 &&
          key != "normalize" &&
          key != "standardize" &&
          key != "outlier"
        ) {
          let postbody = setOfOptions[key];
          Apicalls.encodingcalls2(key, postbody)
            .then((response) => {
              
              let values = response.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: values,
              });
              updateTableColumns();
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 2000);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                // ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 2000);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        } else if (key == "normalize" && tabfilteroptions2.normalize == true) {
          Apicalls.normalize()
            .then((res) => {
              // ButtonLoader(false);
              let response = res.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: response,
              });
              updateTableColumns();
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 2000);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 2000);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        } else if (
          key == "standardize" &&
          tabfilteroptions2.standardize == true
        ) {
          Apicalls.standardize()
            .then((res) => {
              // ButtonLoader(false);
              let response = res.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: response,
              });
              updateTableColumns();
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 2000);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 2000);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        } else if (key == "outlier" && tabfilteroptions2.outlier != "") {
          Apicalls.outlierCall(tabfilteroptions2.outlier)
            .then((res) => {
              // ButtonLoader(false);
              let response = res.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: response,
              });
              updateTableColumns();
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 500);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              setCheckedResponse({ value: listofvalues });
              setTimeout(() => {
                ButtonLoader(false);
                setCheckedResponse({ value: listofvalues });
              }, 2000);
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        }
        else if (
          key == "pearson" &&
          pearsonColumns.x_columns != "" &&
          pearsonColumns.y_columns != ""
        ) {
          let postbody = [
            {
              x_column: pearsonColumns.x_columns,
              y_column: pearsonColumns.y_columns,
            },
          ];
          Apicalls.encodingcalls('pearson_correlation', postbody)
            .then((response) => {
              ButtonLoader(false);
              let values = response.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: values,
              });
              setCheckedResponse({ value: listofvalues });
              updateTableColumns();
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              setCheckedResponse({ value: listofvalues });
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        }
        else if (
          key == "spearman" &&
          spearmanColumns.x_columns != "" &&
          spearmanColumns.y_columns != ""
        ) {
          let postbody = [
            {
              x_column: spearmanColumns.x_columns,
              y_column: spearmanColumns.y_columns,
            },
          ];
          Apicalls.encodingcalls('spearman_correlation', postbody)
            .then((response) => {
              ButtonLoader(false);
              let values = response.data;
              listofvalues.push({
                name: key,
                message: "Updated Successfully",
                color: "green",
                data: values,
              });
              setCheckedResponse({ value: listofvalues });
              updateTableColumns();
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            })
            .catch((err) => {
              ButtonLoader(false);
              listofvalues.push({
                name: key,
                message: err.message,
                color: "red",
                data: false,
              });
              setCheckedResponse({ value: listofvalues });
              innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
            });
        }
        else{
          innercount = innercount +1;
              batchCall(listOfKeys[innercount]);
        }
      }
    }
    batchCall(listOfKeys[innercount])
    ButtonLoader(false);
  };
  function normalizeChecked(e) {
    settabfilter2({
      outlier: tabfilteroptions2.outlier,
      normalize: e.target.checked,
      standardize: tabfilteroptions2.standardize,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: tabfilteroptions2.correlation,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
    });
  }
  function standChecked(e) {
    settabfilter2({
      outlier: tabfilteroptions2.outlier,
      normalize: tabfilteroptions2.normalize,
      standardize: e.target.checked,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: tabfilteroptions2.correlation,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
    });
  }
  let outlierHandler = (e) => {
    settabfilter2({
      outlier: e.target.value,
      normalize: tabfilteroptions2.normalize,
      standardize: tabfilteroptions2.standardize,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: tabfilteroptions2.correlation,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
    });
  };
  let normHandler = (e) => {
    settabfilter2({
      outlier: tabfilteroptions2.outlier,
      normalize: e.target.value == "normalize" ? true : false,
      standardize: e.target.value == "standardize" ? true : false,
      mutual_features: tabfilteroptions2.mutual_features,
      correlation: tabfilteroptions2.correlation,
      pca: tabfilteroptions2.pca,
      ica: tabfilteroptions2.ica,
    });
    setNorm(e.target.value);
  };
  return (
    <Layout>
      <Modal title="Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000} height={700} id="plotly-col-sub">
      <PcaIcaChartHandler
                  type={'heatmap'}
                  chartType={"pca"}
                  xaxis={tableList.columns}
                  yAxis={[]}
                            data={tableList.rows}
                            xaxis1={tableListica.columns}
                            yAxis1={[]}
                            data1={tableListica.rows}
            />
      </Modal>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ position: "relative" }}
      >
        <div className="logo">
          <span className="lnslogo">
            <img src={logo} alt="" style={{ width: "60px" }} />
          </span>
        </div>

        <Menu
          // theme="dark"
          mode="inline"
          style={{
            backgroundColor: "#171717",
            color: "#fff",
            padding: "6%",
            paddingLeft: "4%",
          }}
          defaultSelectedKeys={["general-eda"]}
          items={[
            {
              key:"landing",
              icon:<HomeOutlined />,
              label:"Landing",
            },
            {
              key: "pre-processing",
              icon: <EditOutlined />,
              label: "Preprocessing",
            },
            {
              key: "general-eda",
              icon: <ControlOutlined />,
              label: "General EDA",
            },
            {
              key: "model-building",
              icon: <BuildOutlined />,
              label: "Model Building",
            },
          ]}
          onClick={MenuOnClick}
        />
        <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
          <div style={{ width: "100%", display: "grid", marginBottom: "20%" }}>
            <Button
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              className="trigger"
              style={{ margin: "auto" }}
            >
              {collapsed == true && <MenuUnfoldOutlined />}
              {collapsed == false && <MenuFoldOutlined />}
            </Button>
          </div>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            height: "70px",
            backgroundColor: "#171717",
          }}
        >
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <div style={{ width: "100%", justifyContent: "center" }}>
                  <h1 style={{ textAlign: "center" }}>
                    {" "}
                    EDA TOOLS 4.0 - Powered by{" "}
                    <span className="lnsgopslogo">
                      <img src={logo1} alt="" style={{ width: "100px" }} />
                    </span>
                  </h1>
                </div>
                <div
                  style={{
                    width: "20%",
                    maxWidth: "max-content",
                    justifyContent: "right",
                  }}
                >
                  <Dropdown overlay={menu} style={{ float: "right" }}>
                    <Button
                      onClick={(e) => e.preventDefault()}
                      style={{ color: "white" }}
                    >
                      <UserOutlined />
                      Admin
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "10px ",
            height: "83.3vh",
          }}
        >
          <Row className="dashboard-row-eda">
            <Col span={24} className="dashboard-col">
              <Card className="card-default">
                <Row className="eda-main-row">
                  <Col
                    span={11}
                    style={{ paddingLeft: "unset", height: "100%" }}
                  >
                    <Tabs
                      key="1"
                      tabPosition="left"
                      type="card"
                      style={{ height: "100%" }}
                      onChange={FilterTabs}
                    >
                      <TabPane
                        tab={
                          <>
                            <HddOutlined />
                            Classifications
                          </>
                        }
                        key="1"
                        style={{
                          borderRadius: "10px",
                          paddingLeft: "unset",
                          height: "inherit",
                          overflow: "auto",
                        }}
                      >
                        <div style={{ margin: "4%" }}>
                          <Switch
                            checkedChildren={
                              <>
                                <SearchOutlined /> Table search enabled
                              </>
                            }
                            unCheckedChildren={
                              <>
                                <SearchOutlined /> Enable Search
                              </>
                            }
                            checked={true}
                            style={{ margin: "2%" }}
                            key="c1"
                          />
                          <Switch
                            checkedChildren={
                              <>
                                <FastForwardOutlined /> Pagination
                              </>
                            }
                            unCheckedChildren={
                              <>
                                <FastBackwardOutlined /> Pagination
                              </>
                            }
                            style={{ margin: "2%" }}
                            key="c2"
                            checked={true}
                          />
                          <Switch
                            checkedChildren={
                              <>
                                <SortAscendingOutlined /> Table sorting enabled
                              </>
                            }
                            unCheckedChildren={
                              <>
                                <SortAscendingOutlined /> Enable Sorting
                              </>
                            }
                            checked={true}
                            style={{ margin: "2%" }}
                            key="c3"
                          />
                          <div style={{ border: "1px solid #303030" }}></div>
                          {/* <br/> */}

                          {/* <div style={{display:"flex", flexdirection:"row", justifyContent:"center", marginTop:'10px' }}><Modalpopup /></div> */}
                        
                          {/* <div style={{display:"flex", flexdirection:"row", justifyContent:"center", marginTop:'10px' }}><Modalpopup /></div> */}
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Label encoding <Tooltiplabel />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            mode="multiple"
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={labelEncoding}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "<M8[ns]" 
                                // optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={`{"x_columns":"${optionvalue.title}"}`}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          {/* <div style={{display:"flex", flexdirection:"row", justifyContent:"center", marginTop:'10px' }}><Modalpopup /></div> */}
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            One hot encoding <TooltipOnehot />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            mode="multiple"
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={oneHotEncoding}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "<M8[ns]" 
                                // optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={`{"x_columns":"${optionvalue.title}"}`}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          <div
                            style={{
                              display: "grid",
                              display: "flex",
                              flexdirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: "20px", width: "100px" }}
                              onClick={batchWiseCall}
                               disabled={
                                // chiSquareColumns.x_columns == '' &&
                                // chiSquareColumns.y_columns == '' &&
                                tabfilteroptions.label_encoding.length == 0 &&
                                tabfilteroptions.one_hot_encoding.length == 0 ? true:false
                               }
                            >
                              <LoadingOutlined
                            style={{
                              display: isButtonLoading ? "initial" : "none",
                            }}
                          />
                          <FileAddOutlined 
                            style={{
                              display: !isButtonLoading ? "initial" : "none",
                            }}
                          />{" "}
                              Apply
                            </Button>
                          </div>
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Chi Square test X column <Tooltipchisquare />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={squareTestX}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" 
                                // optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Chi Square test Y column <Tooltipchisquare />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={squareTestY}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" 
                                // optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          
                          <div
                            style={{
                              display: "grid",
                              display: "flex",
                              flexdirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: "20px", width: "100px" }}
                              onClick={batchWiseCall3}
                               disabled={
                                chiSquareColumns.x_columns == '' &&
                                chiSquareColumns.y_columns == '' 
                                // tabfilteroptions.label_encoding.length == 0 &&
                                // tabfilteroptions.one_hot_encoding.length == 0
                                 ? true:false
                               }
                            >
                              <LoadingOutlined
                            style={{
                              display: isButtonLoading ? "initial" : "none",
                            }}
                          />
                          <FileAddOutlined 
                            style={{
                              display: !isButtonLoading ? "initial" : "none",
                            }}
                          />{" "}
                              Apply
                            </Button>
                          </div>
                          <br />
                          <div
                            style={{ marginBottom: "5px", marginTop: "10px" }}
                          >
                            
                            <p>Details </p>
                            <span style={{ float: "right" }}></span>
                          </div>
                          <div
                            style={{
                              boxShadow: "inset 0 0 10px #000000",
                              padding: "4%",
                              overflow: "auto",
                              height: "300px",
                              paddingTop: "4%",
                            }}
                          >
                           
                            {checkedResponse.value.length != 0 &&
                              checkedResponse.value != "" && (
                                <Timeline>
                                  {checkedResponse.value.map((response) => {
                                    return (
                                      <Timeline.Item color={response.color}>
                                        <div
                                          style={{
                                            padding: "2%",
                                            backgroundColor:
                                              "rgba(73, 73, 73,0.3)",
                                            padding: "2%",
                                            marginLeft: "-2%",
                                            maxWidth: "max-content",
                                            borderRadius: "10px",
                                            minWidth: "230px",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: "5px",
                                              marginTop: "10px",
                                              fontSize: "14px",
                                              cursor:'pointer'
                                            }}
                                          >
                                            {response.name} &nbsp;&nbsp;
                                            {response.data != false && (
                                              <>
                                                <Modalpopup
                                                  value={response.data}
                                                  name={response.name}
                                                />
                                              </>
                                            )}
                                          </p>
                                          <p
                                            style={{
                                              marginBottom: "5px",
                                              marginTop: "10px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {response.message}
                                          </p>
                                        </div>
                                      </Timeline.Item>
                                    );
                                  })}
                                </Timeline>
                              )}
                            {checkedResponse.value == "" && (
                              <div style={{ display: "grid", height: "100%" }}>
                                <Empty
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  style={{ margin: "auto" }}
                                >
                                  Please select a filter and apply
                                </Empty>
                              </div>
                            )}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane
                        tab={
                          <>
                            <TableOutlined />
                            Regression Options
                          </>
                        }
                        key="2"
                        style={{
                          borderRadius: "10px",
                          paddingLeft: "unset",
                          height: "inherit",
                          overflow: "auto",
                        }}
                      >
                        <div style={{ margin: "4%" }}>
                          <Switch
                            checkedChildren={
                              <>
                                <SearchOutlined /> Table search enabled
                              </>
                            }
                            unCheckedChildren={
                              <>
                                <SearchOutlined /> Enable Search
                              </>
                            }
                            checked={true}
                            style={{ margin: "2%" }}
                            key="c1"
                          />
                          <Switch
                            checkedChildren={
                              <>
                                <FastForwardOutlined /> Pagination
                              </>
                            }
                            unCheckedChildren={
                              <>
                                <FastBackwardOutlined /> Pagination
                              </>
                            }
                            style={{ margin: "2%" }}
                            key="c2"
                            checked={true}
                          />
                          <Switch
                            checkedChildren={
                              <>
                                <SortAscendingOutlined /> Table sorting enabled
                              </>
                            }
                            unCheckedChildren={
                              <>
                                <SortAscendingOutlined /> Enable Sorting
                              </>
                            }
                            checked={true}
                            style={{ margin: "2%" }}
                            key="c3"
                          />
                          <div style={{ border: "1px solid #303030" }}></div>
                        

                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Mutual features <Tooltipmutual/>
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={Mutual}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Correlation <Tooltipstandardize/>
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={Relation}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Show pearson X column <Tooltipchisquare />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={pearsonX}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Show pearson Y column <Tooltipchisquare />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={pearsonY}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Show spearman X column <Tooltipchisquare />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={spearmanX}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Show spearman Y column <Tooltipchisquare />
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={spearmanY}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          <br />
                          <br/>
                          {/* <Row>
                                 <Col span={12}>
                                    <Checkbox onChange={showOutlier} disabled={tabfilteroptions2.outlier == "show_outlier" || tabfilteroptions2.outlier == ""?false:true} checked={tabfilteroptions2.outlier == "show_outlier"?true:false}>Show outlier</Checkbox>
                                 </Col>
                                 <Col span={12}>
                                    <Checkbox onChange={removeOutlier} disabled={tabfilteroptions2.outlier == "remove_outlier" || tabfilteroptions2.outlier == ""?false:true} checked={tabfilteroptions2.outlier == "remove_outlier"?true:false}>Remove outlier</Checkbox>
                                 </Col>
                              </Row> */}
                          <Radio.Group
                            onChange={outlierHandler}
                            value={tabfilteroptions2.outlier}
                          >
                            <Radio value={"show_outlier"}>Show outlier <Tooltipoutlier/></Radio>
                            <Radio value={"remove_outlier"}>
                              Remove outlier
                            </Radio>
                            <Button
                              type="dashed"
                              onClick={() => {
                                settabfilter2({
                                  outlier: "",
                                  normalize: tabfilteroptions2.normalize,
                                  standardize: tabfilteroptions2.standardize,
                                  mutual_features:
                                  tabfilteroptions2.mutual_features,
                                  correlation: tabfilteroptions2.correlation,
                                  pca: tabfilteroptions2.pca,
                                  ica: tabfilteroptions2.ica,
                                });
                              }}
                            >
                              Clear selection
                            </Button>
                          </Radio.Group>
                          <br />
                          <br />
                          <Radio.Group onChange={normHandler} value={stateNorm}>
                            <Radio value={"normalize"}>Normalize <Tooltipnormalize/></Radio>
                            <Radio value={"standardize"}>Standardize <Tooltipstandardize/></Radio>
                            <Button
                              type="dashed"
                              onClick={() => {
                                setNorm("");
                                settabfilter2({
                                  outlier: tabfilteroptions2.outlier,
                                  normalize: false,
                                  standardize: false,
                                  mutual_features:
                                    tabfilteroptions2.mutual_features,
                                  correlation: tabfilteroptions2.correlation,
                                  pca: tabfilteroptions2.pca,
                                  ica: tabfilteroptions2.ica,
                                });
                              }}
                            >
                              Clear selection
                            </Button>
                          </Radio.Group>
                          <div
                            style={{
                              display: "grid",
                              display: "flex",
                              flexdirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: "20px", width: "100px" }}
                              onClick={batchWiseCall2}
                              disabled={
                                tabfilteroptions2.outlier== ''  &&
                                tabfilteroptions2.normalize == false &&
                                tabfilteroptions2.standardize ==   false &&
                                tabfilteroptions2.mutual_features.length == 0 &&
                                tabfilteroptions2.correlation.length  == 0 &&
                              
                                pearsonColumns.x_columns == '' &&
                                pearsonColumns.y_columns == '' &&
                                spearmanColumns.x_columns == '' &&
                                spearmanColumns.y_columns == ''  ? true:false
                              }
  
                            >
                              <LoadingOutlined
                            style={{
                              display: isButtonLoading ? "initial" : "none",
                            }}
                          />
                          <FileAddOutlined 
                            style={{
                              display: !isButtonLoading ? "initial" : "none",
                            }}
                          />{" "}
                              Apply
                            </Button>
                          </div>
                          <>
                         
                               <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                           PCA/ICA Drop Target <Tooltipchisquare />
                          </p>
                                <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={PcaIca}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                   
                          <Row>
                          <Col
                              span={12}
                              style={{
                                paddingLeft: "unset",
                                maxWidth: "max-content",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: "5px",
                                  marginTop: "10px",
                                }}
                              >
                                PCA
                              </p>
                              <InputNumber
                                size="large"
                                min={2}
                                max={100000}
                                // defaultValue={2}
                                onChange={Pca}
                              />
                            </Col>
                            <Col span={12}>
                              <p
                                style={{
                                  marginBottom: "5px",
                                  marginTop: "10px",
                                }}
                              >
                                ICA
                              </p>
                              <InputNumber
                                size="large"
                                min={2}
                                max={100000}
                                // defaultValue={2}
                                onChange={Ica}
                              />
                            </Col>
                          </Row>
                          </>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            display: "flex",
                            flexdirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            type="primary"
                            style={{ width: "100px" }}
                            onClick={showModal}
                            disabled={
                             
                              tabfilteroptions3.pca  == 0 &&
                              tabfilteroptions3.ica == 0  ? true:false
                            }
                          >
                             <LoadingOutlined
                            style={{
                              display: isButtonLoading ? "initial" : "none",
                            }}
                          />
                          <FileAddOutlined 
                            style={{
                              display: !isButtonLoading ? "initial" : "none",
                            }}
                          />{" "}
                              Apply
                            </Button>
                        </div>
                        <br />
                        <div
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            marginBottom: "5px",
                            marginTop: "10px",
                          }}
                        >
                          <p>Details</p>
                          <span style={{ float: "right" }}></span>
                        </div>
                        <div
                          style={{
                            boxShadow: "inset 0 0 10px #000000",
                            padding: "4%",
                            overflow: "auto",
                            height: "300px",
                            paddingTop: "4%",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        >
                          
                          {checkedResponse.value.length != 0 &&
                            checkedResponse.value != "" && (
                              <Timeline>
                                {checkedResponse.value.map((response) => {
                                  return (
                                    <Timeline.Item color={response.color}>
                                      <div
                                        style={{
                                          padding: "2%",
                                          backgroundColor:
                                            "rgba(73, 73, 73,0.3)",
                                          padding: "2%",
                                          marginLeft: "-2%",
                                          maxWidth: "max-content",
                                          borderRadius: "10px",
                                          minWidth: "230px",
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginBottom: "5px",
                                            marginTop: "10px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {response.name}{" "}
                                          {response.data != false && response.data != "showmodal" && (
                                            <>
                                              <Modalpopup
                                                value={response.data}
                                                name={response.name}
                                              />
                                            </>
                                          )}
                                          {response.data == "showmodal" && (
                                            <span style={{fontSize:'12px',marginBottom:'unset',color:'#095cb5',textDecoration:'underline',fontWeight:'bold',cursor:'pointer',marginLeft:'3px'}} onClick={()=>{setIsModalVisible(isModalVisible => !isModalVisible)}}>View heat map</span>
                                          )}
                                        </p>
                                        <p
                                          style={{
                                            marginBottom: "5px",
                                            marginTop: "10px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {response.message}
                                        </p>
                                      </div>
                                    </Timeline.Item>
                                  );
                                })}
                              </Timeline>
                            )}
                          {checkedResponse.value == "" && (
                            <div style={{ display: "grid", height: "100%" }}>
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                style={{ margin: "auto" }}
                              >
                                Please select a filter and apply
                              </Empty>
                            </div>
                          )}
                        </div>
                      </TabPane>
                      <TabPane
                        tab={
                          <>
                            <LineChartOutlined />
                            Chart Options
                          </>
                        }
                        key="3"
                        style={{
                          borderRadius: "10px",
                          paddingLeft: "unset",
                          height: "inherit",
                          overflow: "auto",
                        }}
                      >
                        <div style={{ margin: "4%" }}>
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Select a chart type
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select chart"
                            onChange={(e) => {
                              setChartOptions(e, "type");
                            }}
                          >
                            {chartTypes.map((type) => {
                              return (
                                <Option value={type} key={type}>
                                  {type}
                                </Option>
                              );
                            })}
                          </Select>
                          {chartType == "heatmkap" && (
                            <>
                              <p
                                style={{
                                  marginBottom: "5px",
                                  marginTop: "10px",
                                }}
                              >
                                Select x axis
                              </p>
                              <Select
                                style={{ width: "100%" }}
                                allowClear
                                multiple="true"
                                showArrow={true}
                                placeholder="Select x axis"
                                onChange={(e) => {
                                  setChartOptions(e, "xaxis");
                                }}
                              >
                                {tableValues.columns.map((type) => {
                                  return (
                                    <Option value={type.title} key={type.title}>
                                      {type.title}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </>
                          )}
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Select columns
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            mode="multiple"
                            showArrow={true}
                            placeholder="Select Columns"
                            onChange={(e) => {
                              setChartOptions(e, "yaxis");
                            }}
                          >
                            {tableValues.columns.map((type) => {
                              return (
                                <Option value={type.title} key={type.title}>
                                  {type.title}
                                </Option>
                              );
                            })}
                          </Select>
                          {chartType == "bar" && (
                            <Checkbox onChange={stackModeHandler}>
                              Stack Bar Chart
                            </Checkbox>
                          )}
                        </div>
                      </TabPane>
                    </Tabs>
                  </Col>
                  <Col
                    span={13}
                    className="eda-content-col-chart"
                    style={{ padding: "1%", height: "100%" }}
                    id="plotly-col"
                  >
                    {isDisplayTable && (
                      <Table
                        dataSource={[...tableValues.rows]}
                        columns={tableValues.columns}
                        className="table-height-eda"
                        loading={isLoading}
                        bordered={true}
                        key="6"
                      />
                    )}
                    {!isDisplayTable && (
                      <PlotlyCharts
                        type={chartType}
                        xaxis={chartXaxis}
                        yAxis={chartYaxis}
                        data={tableValues.rows}
                        stackmode={isStacked}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
        <Footer
          style={{
            padding: 0,
            maxHeight: "max-content",
            backgroundColor: "#171717",
            display: "grid",
          }}
        >
          <h1 style={{ textAlign: "center", margin: "auto", fontSize: "14px" }}>
            All Rights Reserved | © 2022 LivNSense ® Technologies Pvt Ltd
          </h1>
        </Footer>
      </Layout>
    </Layout>
  );
};
export default GeneralEda;
