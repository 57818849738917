import React, { useEffect, useState } from "react";
import { Spin, Row, Col } from "antd";
import Plot from "react-plotly.js";

let PcaIcaChartHandler = (props) => {
  const [chartType, setType] = useState("");
  const [chartXaxis, setx] = useState([]);
  const [chartYaxis, sety] = useState([]);
  const [chartYaxisica, setyica] = useState([]);

  const [isLoaded, Loading] = useState(true);
  const [chartWidth, setWidth] = useState({ width: "", height: "" });
  useEffect(() => {
    Loading(true);
    // console.log(props)
    setType(() => props.type);
    let chartRows = [];
    let chartRowsica = [];
    if (props.data.length != 0) {
      chartRows.push({ type: 'heatmap', z: props.data, y: [], x: props.xaxis });
      sety(() => chartRows);
    }
    else {
      chartRows.push({ type: 'heatmap', z: [], y: [], x: [] });
      sety(() => chartRows);
    }
    if (props.data1.length != 0) {
        chartRowsica.push({ type: 'heatmap', z: props.data1, y: [], x: props.xaxis1 });
        setyica(()=> chartRowsica);
    }
    else {
      chartRowsica.push({ type: 'heatmap', z: [], y: [], x: [] });
      setyica(() => chartRowsica);
    }
    let width = document.getElementById("plotly-col-sub");
    setWidth({ width: 500, height: 400 });
    Loading(false);
  }, [props.type, props.xaxis, props.yAxis, props.data, props.stackmode]);

  return (
    <Spin spinning={isLoaded} style={{ height: "100%" }}>
       <Row gutter={12}>
           <Col span={12}>
            <p style={{fontSize:'16px',textAlign:'center'}}>Pca Heatmap</p>
           {props.type != "ica" && props.chartType == "pca" && (
        <Plot
          data={chartYaxis}
          layout={{
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            barmode: props.stackmode,
            height: chartWidth.height - 50,
            width: chartWidth.width - 50,
            margin: { t: 15, b: 85, l: 35, r: 15 },
            padding: { t: 15, b: 55, l: 35},
            yaxis: {
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              gridcolor: "#303030",
            },
            xaxis: {
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              gridcolor: "#303030",
            },
            legend: {
              font: {
                color: "rgba(255, 255, 255, 0.85)",
                family: "Lato",
                size: 12,
              },
            },
          }}
          config={{ displayModeBar: true }}
          style={{ margin: "auto", marginLeft: "2%" }}
        />
      )}
           </Col>
        <Col span={12}>
        <p style={{fontSize:'16px',textAlign:'center'}}>Ica Heatmap</p>
        {props.type != "pca" &&(
        <Plot
          data={chartYaxisica}
          layout={{
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            barmode: props.stackmode,
            height: chartWidth.height - 50,
            width: chartWidth.width - 50,
            margin: { t: 15, b: 85, l: 35, r: 15 },
            yaxis: {
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              gridcolor: "#303030",
            },
            xaxis: {
              tickfont: {
                family: "Lato",
                size: 12,
                color: "rgba(255, 255, 255, 0.85)",
              },
              gridcolor: "#303030",
            },
            legend: {
              font: {
                color: "rgba(255, 255, 255, 0.85)",
                family: "Lato",
                size: 12,
              },
            },
          }}
          config={{ displayModeBar: true }}
          style={{ margin: "auto", marginLeft: "2%" }}
        />
      )}
        </Col>
       </Row>
    </Spin>
  );
};

export default PcaIcaChartHandler;
