import React, { useEffect, useState, useRef } from "react";
import {
  UploadOutlined,
  UserOutlined,
  FilterOutlined,
  LoadingOutlined,
  InsertRowRightOutlined,
  CloseSquareOutlined,
  CheckSquareOutlined,
  FileAddOutlined,
  TableOutlined,
  UnorderedListOutlined,
  EditOutlined,
  ControlOutlined,
  LayoutOutlined,
  BuildOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  SearchOutlined,
  LineChartOutlined,
  SortAscendingOutlined,
  CommentOutlined,
  FastForwardOutlined,
  FastBackwardOutlined,
  DatabaseOutlined,
  DatabaseFilled,
  BuildFilled,
  SaveFilled,
  HomeOutlined,
  ToTopOutlined
} from "@ant-design/icons";
import {
  Card,
  Table,
  notification,
  Button,
  message,
  Tag,
  Input,
  Checkbox,
  InputNumber,
  Timeline,
  Empty,
  Dropdown,
  Space,
  Layout,
  Menu,
  Tabs,
  Select,
  Row,
  Col,
  Switch,
  Radio,
} from "antd";
import logo from "../assets/lnsgopslogo.png";
import logo1 from "../assets/greenopslogo.png";
import fileData from "../services/utils";
import { useNavigate } from "react-router-dom";
import logo2 from "../assets/bgoutputimage.png";
import Apicalls from "../services/apicalls";
import Highlighter from "react-highlight-words";
import ModelPlotlyCharts from "./ModelChartHandler";
import Modalpopup from "./modal";
import Tooltipinfo from "./TooltipTraindata";
import Tooltiptest from "./TooltipTest";
import { Tooltip } from 'antd';
import TooltipDependent from "./TooltipDependent";
import TooltipIndependent from "./TooltipIndependent";
import {InfoCircleOutlined }from "@ant-design/icons";
import {Modal} from 'antd';
const { TabPane } = Tabs;
const { Option } = Select;
const { Header, Sider, Content, Footer } = Layout;
const { Search } = Input;
let ModelbuildingEDA = () => {
  let navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [tableValues, setTableValues] = useState({ columns: [], rows: [] });
  const [copied, copiedValues] = useState({ columns: [], rows: [] });
  const [isLoading, TableLoad] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isDisplayTable, DisplayTable] = useState(true);
  const [chartXaxis, xAxis] = useState([]);
  const [chartYaxis, yAxis] = useState([]);
  const [chartType, setType] = useState("");
  const [columnDataTypes, setColumns] = useState({ columns: [] });
  const [checkedFilters, CheckedFilters] = useState("");
  const [checkedResponse, setCheckedResponse] = useState({ value: [] });
  const [isStacked, setStacked] = useState("unset");
  const [modelbuild, setBuildValues] = useState({ training: 0.2, testing: 0 });
  const [isButtonLoading, ButtonLoader] = useState(false);
  const [getprojectName,setprojectName]= useState({
    project_name:''
  });
  const [linearColumns, SetLinear] = useState({
    dependent: "",
    independent: [],
  });
  const [saveModel,setModel] = useState(false);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space></Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const TooltipLoadmodelPredict = () => (
    <Tooltip title="Column names and column length should be same as saved model dataset">
      <span><InfoCircleOutlined /></span>
    </Tooltip>
  );
  useEffect(() => {

  let checkToken = localStorage.getItem('AccessToken');
    if (fileData.filevalues == "") {
      return navigate("/landing");
    } 
    else if(checkToken == null || checkToken == undefined){
      return navigate("/login");
    }
    else {
      updateTableColumns();
    }
  }, []);
  function updateTableColumns() {
    TableLoad(true);
    Apicalls.getDataInfo()
      .then((res) => {
        let stringifyResponse = JSON.stringify(res.data);
        let response_parse = [];
        let response = [];
        if(stringifyResponse.includes('NaN')){
          stringifyResponse = stringifyResponse.replaceAll(`NaN`,`null`);
          response_parse = JSON.parse(stringifyResponse);
          response = JSON.parse(response_parse);

        }
        else{
          response = JSON.parse(stringifyResponse);
        }
        let columnDetails = response[0][0];
        let rowDetails = response[1];
        columnDetails = columnDetails.replaceAll(`dtype(`, "");
        //columnDetails = columnDetails.replaceAll(`)`,'');
        columnDetails = columnDetails.replaceAll(`float64')`, `float64'`);
        columnDetails = columnDetails.replaceAll(`int64')`, `int64'`);
        columnDetails = columnDetails.replaceAll(`<M8[ns]')`, `<M8[ns]'`);
        columnDetails = columnDetails.replaceAll(`string')`, `string'`);
        columnDetails = columnDetails.replaceAll(`O')`, `O'`);
        columnDetails = columnDetails.replaceAll(`'`, `"`);
        let defaultKey = "";
        let parsedColumnDetails = JSON.parse(columnDetails);
        let acolumndetails = [];
        let acolumntypes = [];
        for (let key in parsedColumnDetails) {
          acolumndetails.push({
            title: `${key}`,
            dataIndex: `${key}`,
          });
          acolumntypes.push({
            title: key,
            dataIndex: parsedColumnDetails[key],
          });
        }
        // function addRowValues() {
        //   innerCount = innerCount + 1;
        //   let obj = {};
        //   if (innerCount != getLength) {
        //     fileData.columnDetails.map((value) => {
        //       obj[value.title] = rowDetails[value.title][innerCount];
        //     });
        //     rowFinalValues.push(obj);
        //     return addRowValues();
        //   }
        // }
        //console.log(fileData.columnDetails)
        fileData.columnTypes = acolumntypes;
        fileData.columnDetails = acolumndetails;
        defaultKey = fileData.columnDetails[0].title;
        let innerCount = -1;
        let getLength = Object.keys(rowDetails[defaultKey]).length - 1;
        let rowFinalValues = [];
        for(let x=0; x <= getLength -1; x++){
          let obj = {};
          fileData.columnDetails.map((value) => {
            
            if(rowDetails[value.title][x] !== undefined || rowDetails[value.title][x] === null){
              if(rowDetails[value.title][x] === null ){
                obj[value.title] = <Tag color="orange" >Empty value</Tag>;
            }
            else{
              obj[value.title] = rowDetails[value.title][x];
            }
                
            }
            
          });
          let isObjectInjectable = Object.keys(obj).length;
          if(isObjectInjectable != 0){
            rowFinalValues.push(obj)
          }
        }
        //addRowValues();
        fileData.rowDetails = rowFinalValues;
        setColumns({ columns:fileData.columnTypes });
        TableLoad(false);
      })
      .catch((err) => {
        message.error(err.message);
        setColumns({ columns: [] });
        TableLoad(false);
      });
    // }
    // else{
    //   setTableValues({ columns: [], rows: [] });
    //      setSummaryValues({columns: [], rows: []});
    //      copiedValues({ columns: [], rows: [] });
    //      TableLoad(false);
    // }
  }
  function MenuOnClick(e) {
    navigate("/" + e.key);
  }
  // function Logout(){
  //   navigate("/login")
  // }
  function Logout(){
    localStorage.removeItem('AccessToken');
    setTimeout(()=>{
      return navigate("/login");
    },500);
  }
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };
  const showprojectnameModal = ()=>{
    setIsModalVisible(true);
  }
  const handleOk = () => {

    setIsModalVisible(isModalVisible => !isModalVisible);
  };

  const handleCancel = () => {
    // console.log("dsfa")
    setIsModalVisible(isModalVisible => !isModalVisible);
  };

  function setHandler(e,type){
    let getvalue = getprojectName;
    let obj = {};
    let targetValue = '';
      let convert = e.target.value;
      targetValue = convert;

    for(let key in getvalue){
        if(key == type){
          obj[key] = targetValue;
        }
        else{
          obj[key] = getvalue[key];
        }
      
    }
    setprojectName(obj);
}
  const menu = (
    <Menu
      items={[
        // {
        //   label:(
        //     <a onClick={showModal}> Saved projects
        //     </a>
        //   ),
        
        // },
        {
          label: (
            <a
              onClick={Logout}
              // href="https://www.antgroup.com"
            >
              Logout
            </a>
          ),
          key: "0",
        },
      ]}
    />
  );
  function FilterTabs(value) {
    if (value == 1) {
      DisplayTable(true);
    } else if (value == 2) {
      DisplayTable(true);
    } else {
      DisplayTable(false);
    }
  }
  function testing(e) {
    let training = modelbuild.training;
    let calculation = e + training;
    // if (e >= training) {
    //   setBuildValues({ training: 0.2, testing: 0 });
    //   notification["warning"]({
    //     message: "Incorrect combination of testing and training inputs",
    //     description:
    //       "The testing data should be lesser than the entered training data",
    //   });
    // } else {
    //   setBuildValues({ training: modelbuild.training, testing: e });
    // }
    if (e != undefined){
    setBuildValues({ training: e, testing: modelbuild.testing });}
    else{
      setBuildValues({ training: [], testing: modelbuild.testing });
    }
  }
  function training(e) {
    let testing = modelbuild.testing;
    let calculation = testing + e;
    // if (e <= testing) {
    //   setBuildValues({ training: 0.2, testing: 0 });
    //   notification["warning"]({
    //     message: "Incorrect combination of testing and training inputs",
    //     description:
    //       "The training data should be higher than the entered training data",
    //   });
    // } else {
      
    // }
    if (e != undefined){
    setBuildValues({ training: modelbuild.training, testing: e });}
    else{
      setBuildValues({ training: modelbuild.training, testing: []});
    }
  }
  function Dependent(e) {
    if (e != undefined){
    SetLinear({ dependent: e, independent: linearColumns.independent });
    }else{
    SetLinear({ dependent: [], independent: linearColumns.independent });
  }}
  function Independent(e) {
    if (e != undefined){
    SetLinear({ dependent: linearColumns.dependent, independent: e });
  }else{
    SetLinear({ dependent: linearColumns.dependent, independent: [] });
  }}
  let linearCall = () => {
    ButtonLoader(true);
    let getUrlParams = {
      x: linearColumns.independent,
      y: linearColumns.dependent,
      test_size: modelbuild.testing,
      rand_state: modelbuild.training
    };
    setModel(getUrlParams);
    Apicalls.linearModel(getUrlParams)
      .then((response) => {
        ButtonLoader(false);
        let res = response.data;
        let datacolumns = [];
        let dataRows = {};
        for (let key in res) {
          datacolumns.push({
            title: key,
            dataIndex: key,
          });
          dataRows[key] = res[key][0];
        }
        setTableValues({ columns: datacolumns, rows: [dataRows] });
        setCheckedResponse({
          value: [
            {
              name: "Linear Regression request",
              color: "green",
              message: "Successfull",
            },
          ],
        });
      })
      .catch((err) => {
        ButtonLoader(false);
        message.error(err.message);
        setCheckedResponse({
          value: [
            {
              name: "Linear Regression request",
              color: "red",
              message: err.message,
            },
          ],
        });
      });
  }
  function SaveModel(){
    let getUrlParams = saveModel;
    let getname= getprojectName.project_name;
    Apicalls.saveLinearModel(getUrlParams, getname).then(res=>{
      let response = res.data;
      message.success('Model saved successfully');
      }).catch(err=>{
          message.error(err.message);
      });
  }

  function loadPredictModel(){
    let getpostbody = {
      x: linearColumns.independent,
    };
    Apicalls.LoadPredictModel(getpostbody,fileData.loadmodelname).then(res=>{
      let response = res.data;
      let dataRows = response.map(value=>{
          return {
              predicted_value:value
          }
      });
      let datacolumns = [{
          title:'predicted_value',
          dataIndex:'predicted_value'
      }];
      setTableValues({ columns: datacolumns, rows: dataRows });
  
      }).catch(err=>{
          message.error(err.message);
      });
}



  return (
    <Layout>
      <Modal title="Enter Project Name" visible={isModalVisible} onOk={SaveModel} onCancel={handleCancel}>
       <label className="formlabel" >Project Name</label>
       <input  type="text" value={getprojectName.project_name} placeholder="Project Name " className="inputbox" onChange={(e)=>{setHandler(e,'project_name')}}/>
      </Modal>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ position: "relative" }}
      >
        <div className="logo">
          <span className="lnslogo">
            <img src={logo} alt="" style={{ width: "60px" }} />
          </span>
        </div>

        <Menu
          // theme="dark"
          mode="inline"
          style={{
            backgroundColor: "#171717",
            color: "#fff",
            padding: "6%",
            paddingLeft: "4%",
          }}
          defaultSelectedKeys={["model-building"]}
          items={[
            {
              key:"landing",
              icon:<HomeOutlined />,
              label:"Landing",
            },
            {
              key: "pre-processing",
              icon: <EditOutlined />,
              label: "Preprocessing",
            },
            {
              key: "general-eda",
              icon: <ControlOutlined />,
              label: "General EDA",
            },
            {
              key: "model-building",
              icon: <BuildOutlined />,
              label: "Model Building",
            },
          ]}
          onClick={MenuOnClick}
        />
        <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
          <div style={{ width: "100%", display: "grid", marginBottom: "20%" }}>
            <Button
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              className="trigger"
              style={{ margin: "auto" }}
            >
              {collapsed == true && <MenuUnfoldOutlined />}
              {collapsed == false && <MenuFoldOutlined />}
            </Button>
          </div>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            height: "70px",
            backgroundColor: "#171717",
          }}
        >
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <div style={{ width: "100%", justifyContent: "center" }}>
                  <h1 style={{ textAlign: "center" }}>
                    {" "}
                    EDA TOOLS 4.0 - Powered by{" "}
                    <span className="lnsgopslogo">
                      <img src={logo1} alt="" style={{ width: "100px" }} />
                    </span>
                  </h1>
                </div>
                <div
                  style={{
                    width: "20%",
                    maxWidth: "max-content",
                    justifyContent: "right",
                  }}
                >
                  <Dropdown overlay={menu} style={{ float: "right" }}>
                    <Button
                      onClick={(e) => e.preventDefault()}
                      style={{ color: "white" }}
                    >
                      <UserOutlined />
                      Admin
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "10px ",
            height: "83.3vh",
          }}
        >
          <Row className="dashboard-row-eda">
            <Col span={24} className="dashboard-col">
              <Card className="card-default">
                <Row className="eda-main-row">
                  <Col
                    span={11}
                    style={{ paddingLeft: "unset", height: "100%" }}
                  >
                    <Tabs
                      key="1"
                      tabPosition="left"
                      type="card"
                      style={{ height: "100%" }}
                      onChange={FilterTabs}
                    >
                      <TabPane
                        tab={
                          <>
                            <DatabaseFilled />
                            Linear Model Regression
                          </>
                        }
                        key="1"
                        style={{
                          borderRadius: "10px",
                          paddingLeft: "unset",
                          height: "inherit",
                          overflow: "auto",
                        }}
                      >
                        <div style={{ margin: "4%" }}>
                        {fileData.loadmodelresponse.length == 0 &&
                        <>
                          <p
                            style={{
                              marginBottom: "5px",
                              marginTop: "10px",
                              marginTop: "10px",
                            }}
                          >
                            Dependent variable{" "} <TooltipDependent/>
                          </p>
                  
                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={Dependent}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select></>
  }
                          
                          <p style={{ marginBottom: "5px", marginTop: "10px" }}>
                            Independent variable <TooltipIndependent/>
                          </p>

                          <Select
                            style={{ width: "100%" }}
                            allowClear
                            mode="multiple"
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={Independent}
                          >
                            {columnDataTypes.columns.map((optionvalue) => {
                              if (
                                optionvalue.dataIndex != "string" &&
                                optionvalue.dataIndex != "<M8[ns]" &&
                                optionvalue.dataIndex != "O"
                              ) {
                                return (
                                  <Option
                                    value={optionvalue.title}
                                    key={optionvalue.title}
                                  >
                                    {optionvalue.title}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                          {fileData.loadmodelresponse.length == 0 &&
                          <Row>
                            <Col
                              span={12}
                              style={{
                                paddingLeft: "unset",
                                maxWidth: "max-content",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: "5px",
                                  marginTop: "10px",
                                }}
                              >
                                Test size 
                              </p>
                              

                              <InputNumber
                                size="large"
                                step="0.01"
                                min="0.10"
                                max="0.20"
                                // defaultValue="0.10"
                                onChange={training}
                              />
                            </Col>
                            <Col span={12}>
                              <p
                                style={{
                                  marginBottom: "5px",
                                  marginTop: "10px",
                                }}
                              >
                                Random state 
                              </p>
                              <InputNumber
                                size="large"
                                min={0}
                                max={100}
                                defaultValue={0}
                                onChange={testing}
                              />
                            </Col>
                          </Row>}
             {fileData.loadmodelresponse.length == 0 &&
                <div style={{display: "grid",display: "flex", flexdirection: "row",justifyContent: "center",}}>
                    <Button
                              type="primary"
                              style={{ marginTop: "20px", width: "180px", marginRight:"10px" }}
                              onClick={linearCall}
                              disabled={
                                linearColumns.dependent == '' ||
                                linearColumns.independent == 0 ||
                                modelbuild.training == 0.11 ||
                                modelbuild.testing == 0 ?true:false

                              }
                            >
                              <LoadingOutlined
                              style={{
                              display: isButtonLoading ? "initial" : "none",
                            }}
                          />
                              <BuildFilled 
                              style={{
                              display: !isButtonLoading ? "initial" : "none",
                            }}
                          />{" "}
                              Build Model
                        </Button>
                    <Button
                              type="primary"
                              style={{ marginTop: "20px", width: "200px", marginLeft:"20px" }}
                              onClick={showprojectnameModal}
                              disabled={ 
                                tableValues.columns.length == 0 &&
                                tableValues.rows.length == 0
                              }
                            >
                              <SaveFilled />
                              Save Model
                          </Button>   
                          </div>
                          }
                        

                        {fileData.loadmodelresponse.length != 0 &&
                          <div style={{display: "grid",display: "flex",flexdirection: "row",justifyContent: "center",}}>
                          <Button
                              type="primary"
                              style={{ marginTop: "20px", width: "200px", marginRight:"10px" }}
                              onClick={loadPredictModel}
                             
                            >
                              <LoadingOutlined
                              style={{
                              display: isButtonLoading ? "initial" : "none",
                            }}
                          />
                              <ToTopOutlined 
                              style={{
                              display: !isButtonLoading ? "initial" : "none",
                            }}
                          />{" "}
                              Load Predict Model
                          </Button>
                         
                          </div>
                            }


                          <br />
                          <div
                            style={{ marginBottom: "5px", marginTop: "10px" }}
                          >
                            Details<span style={{ float: "right" }}></span>
                          </div>
                          <div
                            style={{
                              boxShadow: "inset 0 0 10px #000000",
                              padding: "4%",
                              overflow: "auto",
                              height: "300px",
                              paddingTop: "4%",
                            }}
                          >
                            {checkedResponse.value.length != 0 &&
                              checkedResponse.value != "" && (
                                <Timeline>
                                  {checkedResponse.value.map((response) => {
                                    return (
                                      <Timeline.Item color={response.color}>
                                        <div
                                          style={{
                                            padding: "2%",
                                            backgroundColor:
                                              "rgba(73, 73, 73,0.3)",
                                            padding: "2%",
                                            marginLeft: "-2%",
                                            maxWidth: "max-content",
                                            borderRadius: "10px",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: "5px",
                                              marginTop: "10px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {response.name}
                                          </p>
                                          <p
                                            style={{
                                              marginBottom: "5px",
                                              marginTop: "10px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {response.message}
                                          </p>
                                        </div>
                                      </Timeline.Item>
                                    );
                                  })}
                                </Timeline>
                              )}
                            {checkedResponse.value == "" && (
                              <div style={{ display: "grid", height: "100%" }}>
                                <Empty
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  style={{ margin: "auto" }}
                                >
                                  Please select a filter and apply
                                </Empty>
                              </div>
                            )}
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </Col>
                  <Col
                    span={13}
                    className="eda-content-col-chart"
                    style={{
                      padding: "1%",
                      height: "100%",
                      overflow: "scroll",
                    }}
                  >
                    {tableValues.columns.length == 0 && (
                      <div className="model-placeholder">
                        <div style={{ margin: "auto" }}>
                          <p>Please select the option and build the model</p>
                        </div>
                      </div>
                    )}
                    {tableValues.columns.length != 0 && (
                      <>
                        <Row>
                          <Col
                            span={18}
                            id="model-chart"
                            style={{ paddingLeft: "unset", padding: "2%" }}
                          >
                            <ModelPlotlyCharts
                              type={"line"}
                              xaxis={tableValues.columns.map((value) => {
                                return value.title;
                              })}
                              yAxis={tableValues.columns.map((value) => {
                                return value.title;
                              })}
                              data={tableValues.rows}
                            />
                          </Col>
                          <Col
                            span={6}
                            id="model-chart-pie"
                            style={{ padding: "2%" }}
                          >
                            <ModelPlotlyCharts
                              type={"pie"}
                              xaxis={tableValues.columns.map((value) => {
                                return value.title;
                              })}
                              yAxis={tableValues.columns.map((value) => {
                                return value.title;
                              })}
                              data={tableValues.rows}
                            />
                          </Col>
                        </Row>
                        <Table
                          dataSource={[...tableValues.rows]}
                          columns={tableValues.columns}
                          className="table-height"
                          bordered={true}
                          key="6"
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
        <Footer
          style={{
            padding: 0,
            maxHeight: "max-content",
            backgroundColor: "#171717",
            display: "grid",
          }}
        >
          <h1 style={{ textAlign: "center", margin: "auto", fontSize: "14px" }}>
            All Rights Reserved | © 2022 LivNSense ® Technologies Pvt Ltd
          </h1>
        </Footer>
      </Layout>
    </Layout>
  );
};
export default ModelbuildingEDA;
