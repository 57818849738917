import { UploadOutlined } from "@ant-design/icons";
import { message, Upload, Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import fileData from "../services/utils";

const Appupload = () => {
  const { Dragger } = Upload;
  let navigate = useNavigate();
  function csvToArray(str, delimiter = ",") {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  }
  const props = {
    name: "file",
    multiple: false,
    action: "//jsonplaceholder.typicode.com/posts/",

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        let reader = new FileReader();
        reader.onload = (e) => {
          fileData.filevalues = csvToArray(e.target.result);
          fileData.filename = info.file.name;
          return navigate("/pre-processing");
        };
        reader.readAsText(info.file.originFileObj);
        //   return navigate('/Dashboarddatacontent',{state:{filedata:info.fileList}});
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div className="uploadwidth">
      {/* <Dragger maxCount={1} showUploadList={false} {...props}>
     <Row>
       <Col span={12}>
       <p style={{marginBottom:'unset',textAlign:'left'}}>Drag and Drop file here</p>
       <p style={{fontSize:'12px',textAlign:'left'}}>Limit 200MB/Csv file only</p>
       </Col>
       <Col span={12} style={{display:'grid'}}>
          <Button type="primary" style={{float:'right',margin:'auto',marginRight:'unset'}}><UploadOutlined /> Upload file</Button>
       </Col>
     </Row>
  </Dragger> */}
      <Upload
        accept=".txt, .csv"
        showUploadList={false}
        beforeUpload={(file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            message.success(`${file.name} file uploaded successfully.`);
            fileData.filevalues = csvToArray(e.target.result);
            fileData.filename = file.name;
            return navigate("/pre-processing");
          };
          reader.readAsText(file);
          return false;
        }}
        style={{ width: "100%" }}
      >
        <Row className="uploadwidth">
          <Col span={12}>
            <p style={{ marginBottom: "unset", textAlign: "left" }}>
              Drag and Drop file here
            </p>
            <p style={{ fontSize: "12px", textAlign: "left" }}>
              Limit 200MB/Csv file only
            </p>
          </Col>
          <Col span={12} style={{ display: "grid" }}>
            <Button
              type="primary"
              style={{ float: "right", margin: "auto", marginRight: "unset" }}
            >
              <UploadOutlined /> Upload file
            </Button>
          </Col>
        </Row>
      </Upload>
    </div>
  );
};

export default Appupload;
