import {
  UploadOutlined,
  UserOutlined,
  FilterOutlined,
  LoadingOutlined,
  InsertRowRightOutlined,
  CloseSquareOutlined,
  CheckSquareOutlined,
  FileAddOutlined,
  TableOutlined,
  UnorderedListOutlined,
  EditOutlined,
  ControlOutlined,
  LayoutOutlined,
  BuildOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  DatabaseOutlined,
  HomeOutlined,
  
  DeleteOutlined
} from "@ant-design/icons";
import { Layout, Menu, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import {
  Card,
  Table,
  Button,
  message,
  Tag,
  Input,
  Checkbox,
  Timeline,
  Empty,
  Dropdown,
  Space,
} from "antd";
// import CsvLink from 'react-csv-export';
import { ExportJsonCsv } from 'react-export-json-csv';

import logo from "../assets/lnsgopslogo.png";
import logo1 from "../assets/greenopslogo.png";
import fileData from "../services/utils";
import { Select } from "antd";
import { Tabs } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Apicalls from "../services/apicalls";
import Tooltipconversion from "./Tooltipconversion";
const { TabPane } = Tabs;
const { Option } = Select;
const { Header, Sider, Content, Footer } = Layout;
const { Search } = Input;
const Dashboardpage = () => {
  let navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [tableValues, setTableValues] = useState({ columns: [], rows: [] });
  const [summaryValues, setSummaryValues] = useState({ columns: "", rows: "" });
  const [copyValues, copiedValues] = useState({ columns: "", rows: "" });
  const [storeSelectedValues, SelectedValues] = useState({ value: [] });
  const [submitToggle, isSubmitDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isLoading, TableLoad] = useState(true);
  const [labelValues, AddLabels] = useState({ data: [], date: [] });
  const [isButtonLoading, ButtonLoader] = useState(false);
  const [missingcolumns, setmissing] = useState({ column: "" });
  const [defaultValues, setDefault] = useState({ default: "" });
  const [checkedFilters, CheckedFilters] = useState("");
  const [checkedResponse, setCheckedResponse] = useState({ value: [] });
  const [sortedInfo, setSortedInfo] = useState({});
  const [isFillNaDropVisible, DropVisible] = useState(false);
  const [dselectOptions, setSelectOptions] = useState([]);
  const [ft, FillTypes] = useState("");
  const [fo, FillOptions] = useState("");
  const [fc, FillColumns] = useState("");
  const [columnDataTypes, setColumns] = useState({ columns: [] });
  const [showFillOptions,setFillOptions] = useState(false);
  const [showButtonOptions,setbuttonoptions] = useState(false)
  const [tabfilteroptions, settabfilter] = useState([]);
  const [isDataConverted,setDataConverted] = useState(false);
  const checkBoxOptions = [
    {
      label: "Drop NaN",
      value: "drop_na",
    },
    {
      label: <div><span onClick={()=>{setbuttonoptions(showButtonOptions=>!showButtonOptions)}}>Fill NaN&nbsp;&nbsp;</span> 
         {showButtonOptions && (
          <Button type="dashed" onClick={
            ()=>{
              setFillOptions(showFillOptions=>!showFillOptions)
            }
          }>Show Options</Button>
         )}
      </div>,
      value: "fill_na",
    },
  ];
  function isInteger(x) {
    let checktype = typeof x === "number" && isFinite(x) && Math.floor(x) === x;
    let divisble = x % 1 === 0;
    if (checktype == true && divisble == true) {
      return true;
    } else {
      return false;
    }
  }
  function isFloat(x) {
    return !!(x % 1);
  }

  useEffect(() => {
    // if (fileData.filevalues == "") {
    //   return navigate("/landing");
    // } else if(fileData.columnDetails.length == 0) {
    //   console.log(fileData.columnDetails.length,fileData.columnDetails)
    let token = localStorage.getItem('AccessToken');
    if (fileData.filevalues == "") {
      return navigate("/landing");
    }
    else if(token == null || token == undefined){
      return navigate("/login");
    } else if(fileData.columnDetails.length == 0) {
      // console.log(fileData.columnDetails.length,fileData.columnDetails)
      let datacolumns = [];
      let missingcolumns = [];
      let datarows = [];
      let keyRef = "";
      let keyvalues = [];
      let filevalues = fileData.filevalues;
      let convertedKeyValues = [];
      filevalues.map((value, index) => {
        if (index == 0) {
          for (let key in value) {
            keyRef = key;
            keyvalues.push(key);
            let newkey = key.toString().replace(/(\r\n|\n|\r)/gm, "");
            newkey = newkey.toString().replace(/\"/g, "");
            convertedKeyValues.push(newkey);
            datacolumns.push({
              title: newkey,
              dataIndex: newkey,
              key:newkey,
              name:newkey
            });
          }
        }
        if (value[keyRef] != undefined) {
          function conversion(value) {
            let removeSlash = value.toString().replace(/\"/g, "");
            removeSlash = removeSlash.replace(/(\r\n|\n|\r)/gm, "");
            let checkInteger = isInteger(Number(removeSlash));
            let checkFloat = isFloat(Number(removeSlash));
            let returnValue = "";
            if (checkInteger == false && checkFloat == false) {
              if (removeSlash == "Yes" || removeSlash == "No") {
                returnValue = removeSlash;
              } else {
                returnValue = removeSlash;
              }
            } else if (checkInteger == true) {
              returnValue = parseInt(removeSlash);
            } else if (checkFloat == true) {
              returnValue = parseFloat(removeSlash);
            } else {
              returnValue = removeSlash;
            }

            return returnValue;
          }
          let resObj = {};
          for (let i = 0; i < convertedKeyValues.length; i++) {
            let missing = conversion(value[keyvalues[i]]);
            if (Number.isNaN(missing) == false) {
              resObj[convertedKeyValues[i]] = conversion(value[keyvalues[i]]);
            } else {
              let keyref1 = convertedKeyValues[i];

              // missingcolumns.map((value) => {
              //   if (value.columnname == keyref1) {
              //     value.items = value.items + 1;
              //   }
              // });
              resObj[convertedKeyValues[i]] = "";
            }
          }
          datarows.push(resObj);
        }
      });
      datarows.map(value=>{
        for(let key in value){
          if(value[key] === ""){
            value[key] = null
          }
        }
      })
      //api call
      Apicalls.save(datarows)
      // console.log("error", Apicalls)
        .then((response) => {
          let valueresponse = response.data;
          let responses = [];
          if(valueresponse.includes('NaN')){
            let values = JSON.stringify(valueresponse);
            values = values.replaceAll('NaN',null);
            let parsedValues = JSON.parse(values);
            parsedValues = JSON.parse(parsedValues)
            responses = parsedValues[1];
          }
          else{
            responses = response.data[1];
          }
          //console.log(response.data,response.data[1],responses[0],responses)
          let firstresponse = converData(responses[0], "datatypes");
          setDefault({ default: firstresponse });
          updateTableColumns();
          
          
          // setTableValues({ columns: datacolumns, rows: datarows });
          // setSummaryValues({columns:datacolumns,rows:datarows});
          // copiedValues({ columns: datacolumns, rows: datarows });
        })
        .catch((err) => {
          // setTableValues({ columns: [], rows: []});
          // setSummaryValues({columns: [], rows: []});
          // copiedValues({ columns: [], rows: [] });
          
          
          message.error(err.message);
          updateTableColumns();
        });
    }
    else{
      updateTableColumns();
    }
  }, []);
  useEffect(() => {}, [checkedResponse.value]);
  const handleChange = (value) => {
    //console.log(value)
    let values = JSON.parse(value);
    let tablerows = copyValues.rows.map((a) => Object.assign({}, a));
    let columnobj = "";
    if (values.apikey == "default") {
      let type = "";
      tablerows.map((innerobj) => {
        for (let key in innerobj) {
          if (key == values.name && values.apikey == "default") {
            let removeSlash = innerobj[key];
            let checkInteger = isInteger(Number(removeSlash));
            let checkFloat = isFloat(Number(removeSlash));
            if (checkInteger == false && checkFloat == false) {
              if (removeSlash == "Yes" || removeSlash == "No") {
                type = "bool";
              }
            } else if (checkInteger == true) {
              type = "int64";
            } else if (checkFloat == true) {
              type = "float64";
            } else {
              type = typeof removeSlash;
            }
          }
        }
      });
      columnobj = {
        column_name: values.name,
        data_type: type,
      };
    } else {
      columnobj = {
        column_name: values.name,
        data_type: values.apikey,
      };
    }
    let testvalues = JSON.parse(JSON.stringify(storeSelectedValues.value));
    let saveresults = [];
    //console.log(saveresults)
    SelectedValues({ value: [...storeSelectedValues.value, columnobj] });
    isSubmitDisabled(false);
  };
  function Deletecolumns(value) {
    let e = [];
    value.map((values) => {
      e.push(JSON.parse(values));
    });
    settabfilter(e)
  }
  function removeColumns(){
     let deleteColumns = tabfilteroptions;
     if(deleteColumns.length != 0 ){
        Apicalls.deleteColumn(deleteColumns).then(res=>{
            let response = res.data;
            // console.log(response)
            if(response.includes('wrong request')){
               message.error(response);
            }
            else{
              message.success(response);
              updateTableColumns();
            }

        }).catch(err=>{
             message.error(err.message);
        })
     }
  }
  function updateTableColumns() {
    TableLoad(true);
    Apicalls.getDataInfo()
      .then((res) => {
        //let response = res.data;
        let stringifyResponse = JSON.stringify(res.data);
        let response_parse = [];
        let response = [];
        if(stringifyResponse.includes('NaN')){
          stringifyResponse = stringifyResponse.replaceAll(`NaN`,`null`);
          response_parse = JSON.parse(stringifyResponse);
          response = JSON.parse(response_parse);

        }
        else{
          response = JSON.parse(stringifyResponse);
        }
        let columnDetails = response[0][0];
        let rowDetails = response[1];
        columnDetails = columnDetails.replaceAll(`dtype(`, "");
        //columnDetails = columnDetails.replaceAll(`)`,'');
        columnDetails = columnDetails.replaceAll(`float64')`, `float64'`);
        columnDetails = columnDetails.replaceAll(`int64')`, `int64'`);
        columnDetails = columnDetails.replaceAll(`<M8[ns]')`, `<M8[ns]'`);
        columnDetails = columnDetails.replaceAll(`string')`, `string'`);
        columnDetails = columnDetails.replaceAll(`O')`, `O'`);
        columnDetails = columnDetails.replaceAll(`'`, `"`);
        let defaultKey = "";
        let parsedColumnDetails = JSON.parse(columnDetails);
        let acolumndetails = [];
        let acolumntypes = [];
        for (let key in parsedColumnDetails) {
          acolumndetails.push({
            title: `${key}`,
            dataIndex: `${key}`,
            key:`${key}`,
              name:`${key}`
          });
          acolumntypes.push({
            title: key,
            dataIndex: parsedColumnDetails[key],
            key:parsedColumnDetails[key],
              name:parsedColumnDetails[key]
          });
        }
        // function addRowValues() {
        //   innerCount = innerCount + 1;
        //   let obj = {};
        //   if (innerCount != getLength) {
        //     fileData.columnDetails.map((value) => {
        //       obj[value.title] = rowDetails[value.title][innerCount];
        //     });
        //     rowFinalValues.push(obj);
        //     return addRowValues();
        //   }
        // }
        //console.log(fileData.columnDetails)
        fileData.columnTypes = acolumntypes;
        fileData.columnDetails = acolumndetails;
        defaultKey = fileData.columnDetails[0].title;
        let innerCount = -1;
        let getLength = Object.keys(rowDetails[defaultKey]).length - 1;
        let rowFinalValues = [];
        for(let x=0; x <= getLength -1; x++){
          let obj = {};
          fileData.columnDetails.map((value) => {
            
            if(rowDetails[value.title][x] !== undefined || rowDetails[value.title][x] === null){
              if(rowDetails[value.title][x] === null ){
                obj[value.title] = <Tag color="orange" >Empty value</Tag>;
            }
            else{
              obj[value.title] = rowDetails[value.title][x];
            }
                
            }
            
          });
          let isObjectInjectable = Object.keys(obj).length;
          if(isObjectInjectable != 0){
            rowFinalValues.push(obj)
          }
        }

        //addRowValues();
        fileData.rowDetails = rowFinalValues;
        setTableValues({
          columns: fileData.columnDetails,
          rows: rowFinalValues,
        });
        setSummaryValues({
          columns: fileData.columnDetails,
          rows: rowFinalValues,
        });
        copiedValues({ columns: fileData.columnDetails, rows: rowFinalValues });
        TableLoad(false);
      })
      .catch((err) => {
        message.error(err.message);
        setTableValues({ columns: [], rows: [] });
        copiedValues({ columns: [], rows: [] });
        setSummaryValues({ columns: [], rows: [] });
        TableLoad(false);
      });
    // }
    // else{
    //   setTableValues({ columns: [], rows: [] });
    //      setSummaryValues({columns: [], rows: []});
    //      copiedValues({ columns: [], rows: [] });
    //      TableLoad(false);
    // }
  }
  const handleSubmit = () => {
    ButtonLoader(true);
    if (storeSelectedValues.value.length != 0) {
      let dataTypes = storeSelectedValues.value;
      let uploadDate = new Date();
      fileData.changedDataTypes = dataTypes;
      Apicalls.changeData(dataTypes)
        .then((response) => {
          setTimeout(() => {
            ButtonLoader(false);
            AddLabels({ data: dataTypes, date: uploadDate.toLocaleString() });
            message.success("Completed");
            updateTableColumns();
            setDataConverted(true);
          }, 2000);
        })
        .catch((err) => {
          ButtonLoader(false);
          message.error(err.message);
        });
    }
  };
  let checkBoxChange = (checkedValues) => {
    
    CheckedFilters(checkedValues);
    // if(checkedValues == "fill_na" || checkedFilters.includes('fill_na')){
    //   setFillOptions(true);
    // }
    // else {
    //   setFillOptions(false)
    // }
  };
  function fillType(value) {
    if (value == "Category_variable") {
      setSelectOptions(["Zero"]);
    } else {
      setSelectOptions(["Mean", "Mode", "Interpolate","Zero"]);
    }
    FillTypes(value);
  }
  function fillOptions(values) {
    FillOptions(values);
  }
  function fillColumns(value) {
    FillColumns(value);
  }
  let batchWiseCall = () => {
    let countOfValues = checkedFilters.length;
    let count = 0;
    let listofvalues = [{ name: "default", message: "some", color: "blue" }];
    listofvalues.length = 0;
    setCheckedResponse({ value: "" });
    function makeApiCall(count) {
      if (count == countOfValues - 1) {
        
        let countname = checkedFilters[count].slice();
        if (checkedFilters[count] == "fill_na") {
          let data = {
            column_type: ft,
            column_name: fc,
            method: fo,
          };
          Apicalls.fillPost([data])
            .then((response) => {
              listofvalues.push({
                name: countname,
                message: "Updated Successfully",
                color: "green",
              });
              makeaList();
              updateTableColumns();
            })
            .catch((err) => {
              listofvalues.push({
                name: countname,
                message: "Please choose the columns to convert the data type",
                color: "red",
              });
              makeaList();
              updateTableColumns();
            });
        } else {
          Apicalls.get(checkedFilters[count])
            .then((response) => {
              listofvalues.push({
                name: countname,
                message: "Updated Successfully",
                color: "green",
              });
              makeaList();
              updateTableColumns();
            })
            .catch((err) => {
              listofvalues.push({
                name: countname,
                message: err.message,
                color: "red",
              });
              makeaList();
              updateTableColumns();
            });
        }
      }

      if (count < countOfValues - 1) {
        let countname = checkedFilters[count].slice();
        if (checkedFilters[count] == "fill_na") {
          let data = {
            column_type: ft,
            column_name: fc,
            method: fo,
          };
          Apicalls.fillPost([data])
            .then((response) => {
              listofvalues.push({
                name: countname,
                message: "Updated Successfully",
                color: "green",
              });
              count = count + 1;
              return makeApiCall(count);
            })
            .catch((err) => {
              listofvalues.push({
                name: countname,
                message: err.message,
                color: "red",
              });
              count = count + 1;
              return makeApiCall(count);
            });
        } else {
          Apicalls.get(checkedFilters[count])
            .then((response) => {
              listofvalues.push({
                name: countname,
                message: "Updated Successfully",
                color: "green",
              });
              count = count + 1;
              return makeApiCall(count);
            })
            .catch((err) => {
              listofvalues.push({
                name: countname,
                message: err.message,
                color: "red",
              });
              count = count + 1;
              return makeApiCall(count);
            });
        }
      }
    }
    function makeaList() {
      setCheckedResponse({ value: listofvalues });
    }
    makeApiCall(count);
  };
  function renderLabels() {
    return (
      <div style={{ margin: "20px" }}>
        <p style={{ textAlign: "right" }}>
          Last updated on - {labelValues.date}
        </p>
        {labelValues.data.map((value) => {
          return (
            <>
              <div
                style={{
                  padding: "4%",
                  background: "#494949",
                  borderRadius: "10px",
                }}
              >
                Converted column - <b>{value.column_name}</b> with data type -{" "}
                <b>{value.data_type}</b>
              </div>
              <br />
            </>
          );
        })}
      </div>
    );
  }
  function innerSummary(value) {
    TableLoad(true);
    if (value == "load_data") {
      setSummaryValues({ columns: copyValues.columns, rows: copyValues.rows });
      TableLoad(false);
    } else {
      Apicalls.summary(value)
        .then((response) => {
          let responseData = [];
          let innerData = [];
          if(value == "show_categorical"){
            responseData = response.data;
            innerData = responseData;
          }
          else{
            responseData = response.data[0];
            innerData = responseData[0];
            innerData = innerData.replaceAll(`'`, `"`);
          }
          let allkeynames = [];
          if (innerData == "{}") {
            setSummaryValues({ columns: [], rows: [] });
          } else if (value == "statistical_summary") {
            let redableJson = JSON.parse(innerData);
            // console.log("error", redableJson)
            let columns = [];
            let rows = {};
            columns.push({
                title: 'Type',
                dataIndex: 'Type',
                key:'Type',
                name:'Type'
            })
            for (let key in redableJson) {
              
              columns.push({
                title: key,
                dataIndex: key,
                key:key,
                name:key
              });
              let iterableObj = redableJson[key];
              // console.log("error", iterableObj)
              for (let ikey in iterableObj) {
                allkeynames.push(ikey);
              }
            }
            allkeynames = [...new Set(allkeynames)];
            // console.log("error", allkeynames)
            let lisofkeys= ['count','mean','std','min','25%','50%','75%','max'];
            let newarr = [];
              lisofkeys.map(kvalue=>{
                 let obj = {};
                 let arr = [];
                 let newobj = {};
                 for(let newkey in redableJson){
                    let iobj = redableJson[newkey];
                    arr.push(iobj[kvalue]);
                    newobj = newkey;
                 }
                 arr.map((value,index)=>{
                    obj['Type'] = kvalue;
                    obj[columns[index+1].title] = value;
                 });
                 newarr.push(obj);
              })
            setSummaryValues({ columns: columns, rows: newarr });
          } 
          else if (value == "column_info") {
            let redableJson = JSON.parse(innerData);
            let columns = [];
            let rows = [redableJson["value"]];
            for (let key in redableJson["value"]) {
              columns.push({
                title: key,
                dataIndex: key,
              });
            }
            setSummaryValues({ columns: columns, rows: rows });
          } else if (value == "show_numeric") {
            let columns = [
              {
                title: "Numeric values",
                dataIndex: "Numeric values",
                key:"Numeric values",
                name:"Numeric values"
              },
            ];
            let rows = [{ "Numeric values": innerData }];
            setSummaryValues({ columns: columns, rows: rows });
            // let redableJson = JSON.parse(innerData);
            // let columns = [];
            // let rows = [redableJson["value"]];
            // for (let key in redableJson["value"]) {
            //   columns.push({
            //     title: key,
            //     dataIndex: key,
            //   });
            // }
            // setSummaryValues({ columns: columns, rows: rows });
          } else if (value == "number_count_summary") {
            let redableJson = JSON.parse(innerData);
            let columns = [];
            let rows = {};
            for (let key in redableJson) {
              
              columns.push({
                title: key,
                dataIndex: key,
                key:key,
                name:key
              });
              let iterableObj = redableJson[key];
             
              for (let ikey in iterableObj) {
                allkeynames.push(ikey);
              }
            }
            allkeynames = [...new Set(allkeynames)];
           
            let uniqueObj = [];
           
            columns.map((value) => {
              let obj = {};
              for (let newkey in redableJson) {
                let innerObj = redableJson[newkey];
                allkeynames.map((newvalues) => {
                  if (newvalues in innerObj) {
                    obj[newkey] = innerObj[newvalues];
                  } else {
                    obj[newkey] = "";
                  }
                });
              }
              uniqueObj.push(obj);
            });
            let uniqueRows = uniqueObj.reduce(
              (function (hash) {
                return function (prev, curr) {
                  !hash[curr.count] && (hash[curr.count] = prev.push(curr));
                  return prev;
                };
              })(Object.create(null)),
              []
            );
            setSummaryValues({ columns: columns, rows: uniqueRows });
          } else if (value == "show_categorical") {
             let listOfData = innerData;
             let columns = [];
             let rows = innerData;
             listOfData.map((value,index)=>{
               if(index == 0){
                for(let key in value){
                   columns.push({
                    title:key,
                    dataIndex:key,
                    key:key,
                    name:key
                   });
                }
               } 
             });
             rows.map(value=>{
                for(let key in value){
                  if(value[key] === null){
                    value[key] = <Tag color="warning">Empty value</Tag>
                  }
                }
             })
             setSummaryValues({columns:columns,rows:rows});
          }
          TableLoad(false);
        })
        .catch((err) => {
          message.error(err.message);
          TableLoad(false);
        });
    }
  }
  function converData(object, type) {
    let replaceChar = object;
    replaceChar = replaceChar.replaceAll(`'`, `"`);
    replaceChar = replaceChar.replaceAll(`dtype(`, ``);
    replaceChar = replaceChar.replaceAll(`)`, ``);
    replaceChar = JSON.parse(replaceChar);
    let convertedObject = {};
    if (type == "datatypes") {
      for (let key in replaceChar) {
        if (replaceChar[key] == "O") {
          convertedObject[key] = "Object";
        } else {
          convertedObject[key] = replaceChar[key];
        }
      }
    } else if (type == "pass") {
      let arr = [];
      for (let key in replaceChar) {
        let obj = {};
        obj["columnname"] = key;
        obj["items"] = replaceChar[key];
        arr.push(obj);
      }
      convertedObject = arr;
    } else {
      convertedObject = replaceChar;
    }
    return convertedObject;
  }
  function FilterTabs(value) {
    if (value == 3) {
      Apicalls.missingData()
        .then((response) => {
          let apiresponse = response.data[0];

          let convertedResponse = converData(apiresponse[0], "pass");
          
          setmissing({ column: convertedResponse });
        })
        .catch((err) => {
          setmissing({ column: [] });
        });
    }
  }
  function MenuOnClick(e) {
    navigate("/" + e.key);
  }
  // function Logout(){
  //   navigate("/login");
  // }
  function Logout(){
    localStorage.removeItem('AccessToken');
    setTimeout(()=>{
      return navigate("/login");
    },500);
  }
  const menu = (
    <Menu
      items={[
        {
          label: (
            <a
              onClick={Logout}
              
            >
              Logout
            </a>
          ),
          key: "0",
        },
      ]}
    />
  );
  useEffect(() => {
    if (checkedFilters.includes("fill_na") && checkedFilters.length == 2) {
      console.log('if')
      DropVisible((isFillNaDropVisible) => !isFillNaDropVisible);
    } else if (
      !checkedFilters.includes("fill_na") &&
      checkedFilters.length == 1
    ) {
      console.log('elif')
      DropVisible(false);
    } else {
      console.log('else')
      DropVisible(true);
    }
  }, [checkedFilters]);
  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ position: "relative" }}
      >
        <div className="logo">
          <span className="lnslogo">
            <img src={logo} alt="" style={{ width: "60px" }} />
          </span>
        </div>

        <Menu
          // theme="dark"
          mode="inline"
          style={{
            backgroundColor: "#171717",
            color: "#fff",
            padding: "6%",
            paddingLeft: "4%",
          }}
          defaultSelectedKeys={["pre-processing"]}
          items={[
            {
              key:"landing",
              icon:<HomeOutlined />,
              label:"Landing",
            },
            {
              key: "pre-processing",
              icon: <EditOutlined />,
              label: "Preprocessing",
            },
            {
              key: "general-eda",
              icon: <ControlOutlined />,
              label: "General EDA",
            },
            {
              key: "model-building",
              icon: <BuildOutlined />,
              label: "Model Building",
            },
          ]}
          onClick={MenuOnClick}
        />
        <div style={{ width: "100%", position: "absolute", bottom: "0" }}>
          <div style={{ width: "100%", display: "grid", marginBottom: "20%" }}>
            <Button
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              className="trigger"
              style={{ margin: "auto" }}
            >
              {collapsed == true && <MenuUnfoldOutlined />}
              {collapsed == false && <MenuFoldOutlined />}
            </Button>
          </div>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            height: "70px",
            backgroundColor: "#171717",
          }}
        >
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <div style={{ width: "100%", justifyContent: "center" }}>
                  <h1 style={{ textAlign: "center" }}>
                    {" "}
                    EDA TOOLS 4.0 - Powered by{" "}
                    <span className="lnsgopslogo">
                      <img src={logo1} alt="" style={{ width: "100px" }} />
                    </span>
                  </h1>
                </div>
                <div
                  style={{
                    width: "20%",
                    maxWidth: "max-content",
                    justifyContent: "right",
                  }}
                >
                  <Dropdown overlay={menu} style={{ float: "right" }}>
                    <Button
                      onClick={(e) => e.preventDefault()}
                      style={{ color: "white" }}
                    >
                      <UserOutlined />
                      Admin
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "10px ",
            height: "83vh",
          }}
        >
          <Row className="dashboard-row">
            <Col span={10} className="dashboard-col">
              <Card
                className="card"
                size="small"
                style={{ height: "100%", minHeight: "582px", overflow: "auto" }}
              >
                <Tabs defaultActiveKey="2" type="card" onChange={FilterTabs}>
                  <TabPane tab="Filters" key="1" className="table-pan">
                    <div style={{ padding: "2%", height: "100%" }}>
                      <Checkbox.Group
                        options={checkBoxOptions}
                        onChange={checkBoxChange}
                      />
                      <br />
                      <br />
                      {isFillNaDropVisible &&
                        showFillOptions && (
                          <Row>
                            <Col span={8} style={{ paddingLeft: "unset" }}>
                              <p style={{ marginBottom: "10px" }}>
                                Select Fill Type
                              </p>
                              <Select
                                onChange={fillType}
                                allowClear
                                style={{
                                  minWidth: "100%",
                                  maxWidth: "max-content",
                                }}
                              >
                                <Option value="Category_variable">
                                  Categorical{" "}
                                </Option>
                                <Option value="Numerical_variables">
                                  Numerical
                                </Option>
                              </Select>
                            </Col>
                            <Col span={8}>
                              <p style={{ marginBottom: "10px" }}>
                                Select options
                              </p>
                              <Select
                                onChange={fillOptions}
                                allowClear
                                style={{
                                  minWidth: "100%",
                                  maxWidth: "max-content",
                                }}
                              >
                                {dselectOptions.map((value) => {
                                  return (
                                    <Option value={value} key={value}>
                                      {value}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Col>
                            <Col span={8}>
                              <p style={{ marginBottom: "10px" }}>
                                Select column
                              </p>
                              <Select
                                onChange={fillColumns}
                                allowClear
                                style={{
                                  minWidth: "100%",
                                  maxWidth: "max-content",
                                }}
                              >
                                {tableValues.columns.map((value) => {
                                  return (
                                    <Option
                                      value={value.title}
                                      key={value.title}
                                    >
                                      {value.title}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Col>
                          </Row>
                        )}
                      <br />
                      <div style={{ display: "grid" }}>
                        <Button
                          type="primary"
                          disabled={checkedFilters != "" ? false : true}
                          style={{ margin: "auto" }}
                          onClick={batchWiseCall}
                        >
                          <FileAddOutlined />
                          Apply
                        </Button>
                      </div>
                      <br />
                      <p style={{ marginBottom: "10px" }}>Details</p>
                      <div
                        style={{
                          boxShadow: "inset 0 0 10px #000000",
                          padding: "4%",
                          overflow: "auto",
                          height: "264px",
                          paddingTop: "4%",
                        }}
                      >
                        {checkedResponse.value.length != 0 &&
                          checkedResponse.value != "" && (
                            <Timeline>
                              {checkedResponse.value.map((response) => {
                                return (
                                  <Timeline.Item color={response.color}>
                                    <div
                                      style={{
                                        padding: "2%",
                                        backgroundColor: "rgba(73, 73, 73,0.3)",
                                        padding: "2%",
                                        marginLeft: "-2%",
                                        maxWidth: "max-content",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          marginBottom: "10px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {response.name}
                                      </p>
                                      <p
                                        style={{
                                          marginBottom: "10px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {response.message}
                                      </p>
                                    </div>
                                  </Timeline.Item>
                                );
                              })}
                            </Timeline>
                          )}
                        {checkedResponse.value == "" && (
                          <div style={{ display: "grid", height: "100%" }}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              style={{ margin: "auto" }}
                            >
                              Please select a filter and apply
                            </Empty>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <FilterOutlined />
                        Data Types
                      </span>
                    }
                    key="2"
                    className="table-pan"
                  >
                    <>
                      <p style={{ marginBottom: "5px" }}>Change Data Types <Tooltipconversion/></p>
                      <div className="ant-table-content ">
                        <table style={{ width: "100%" }}>
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell">Column Name</th>
                              <th className="ant-table-cell">Data type</th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            {tableValues.columns != "" &&
                              tableValues.columns.map((value) => {
                                let availableTypes = [
                                  "float64",
                                  "int64",
                                  "bool",
                                  "object",
                                  "datetime64[ns]",
                                ];
                                let defaultvalue = `{"name":"${value.title}","apikey":"default"}`;
                                return (
                                  <>
                                    <tr className="ant-table-row ant-table-row-level-0">
                                      <td className="ant-table-cell">
                                        {value.title}
                                      </td>
                                      <td className="ant-table-cell">
                                        <Select
                                          defaultValue={defaultvalue}
                                          style={{ width: 120 }}
                                          onChange={handleChange}
                                        >
                                          <Option value={defaultvalue}>
                                            Default
                                          </Option>
                                          {availableTypes.map((values) => {
                                            let opvalue = `{"name":"${value.title}","apikey":"${values}"}`;
                                            return (
                                              <Option value={opvalue}>
                                                {values}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                    <div style={{ display: "grid", padding: "2%" }}>
                      <div style={{ margin: "auto" }}>
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          disabled={submitToggle}
                        >
                          <LoadingOutlined
                            style={{
                              display: isButtonLoading ? "inherit" : "none",
                            }}
                          />
                          <UploadOutlined
                            style={{
                              display: !isButtonLoading ? "inherit" : "none",
                            }}
                          />{" "}
                          Change Data Type
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <InsertRowRightOutlined />
                        Column Details
                      </span>
                    }
                    key="3"
                    className="table-pan"
                  >
                    <p>Missing Column Data and column data types</p>
                    <div className="ant-table-content ">
                      <table style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell">Column Name</th>
                            <th className="ant-table-cell">Missing Data</th>
                            <th className="ant-table-cell">
                              Default data types
                            </th>
                            {isDataConverted == true && (
                              <th className="ant-table-cell">
                              Converted data types
                            </th>
                            )}
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          {missingcolumns.column != "" &&
                            missingcolumns.column.map((value, index) => {
                              return (
                                <>
                                  <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell">
                                      {value.columnname}
                                    </td>
                                    <td className="ant-table-cell">
                                      {value.items != 0 && (
                                        <Tag color="orange">
                                          <CloseSquareOutlined />
                                          &nbsp;&nbsp;
                                          <b>{value.items} data Missed</b>
                                        </Tag>
                                      )}
                                      {value.items == 0 && (
                                        <Tag color="green">
                                          <span style={{ fontSize: "14px" }}>
                                            <CheckSquareOutlined />
                                          </span>
                                          &nbsp;&nbsp;<b>All data available</b>
                                        </Tag>
                                      )}
                                    </td>
                                    <td>
                                      <Tag
                                        key={index}
                                        style={{
                                          margin: "1%",
                                          fontSize: "14px",
                                        }}
                                        color="cyan"
                                      >
                                        <DatabaseOutlined />
                                        &nbsp;&nbsp;
                                        {
                                          defaultValues.default[
                                            value.columnname
                                          ]
                                        }
                                      </Tag>
                                    </td>
                                    {isDataConverted == true && (
                                       <td>
                                          {fileData.columnTypes.map(filec=>{
                                              if(filec.title == value.columnname){
                                                 if(filec.dataIndex == "<M8[ns]"){
                                                    return <Tag color="yellow"
                                                              style={{
                                                                margin: "1%",
                                                                fontSize: "14px",
                                                              }}
                                                            >
                                                              Date
                                                            </Tag>
                                                 }
                                                 else{
                                                    return <Tag color="yellow"
                                                    style={{
                                                      margin: "1%",
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    {filec.dataIndex}
                                                  </Tag>
                                                 }
                                              }
                                          })}
                                       </td>
                                    )}
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <DeleteOutlined />
                        Delete columns
                      </span>
                    }
                    key="4"
                    className="table-pan">
                      <p>Delete Columns</p>
                      <Select
                            style={{ width: "100%" }}
                            allowClear
                            mode="multiple"
                            showArrow={true}
                            placeholder="Select columns"
                            onChange={Deletecolumns}
                          >
                            {tableValues.columns.map((value,index)=>{
                              return <Option value={`{"column_name":"${value.title}"}`}
                               key={value.title+index+1}>{value.title}</Option>
                            })}
                          </Select> 
                          <div>
                            <br/>
                          <Button type="primary"
                      onClick={removeColumns}><DeleteOutlined />Delete columns</Button>
                          </div>
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
            <Col span={14} className="dashboard-col" style={{minWidth:'63%'}}>
              <Card
                size="small"
                className="card-default-dt"
                style={{ height: "100%", minHeight: "583px", overflow: "auto" }}
              >
                <Tabs defaultActiveKey="5" type="card">
                  <TabPane
                    tab={
                      <span>
                        <TableOutlined /> 
                        Live Table
                      </span>
                    }
                    key="5"
                  >
                    <Row style={{ marginLeft: "unset", marginRight: "unset" }}>
                      <Col
                        span={20}
                        style={{
                          display: "grid",
                          paddingLeft: "0",
                          paddingRight: "0",
                        }}
                      >
                        <p style={{ margin: "auto", marginLeft: "unset" }}>
                          File Name:&nbsp;{fileData.filename}
                        </p>
                      </Col>
                      <Col span={4}>
                      {/* <CsvLink 
                      data={fileData.rowDetails} 
                      fileName={fileData.filename}withTimeStamp> */}
                    <ExportJsonCsv
                    style={{color:"#fff", backgroundColor:"#177ddc", width:"80px", padding:"3px", float:"right"}}
                    headers={fileData.columnDetails}
                    items= {fileData.rowDetails}
                    
                    >                 
                          Export 

                      </ExportJsonCsv>
                      {/* </CsvLink> */}

                      </Col>
                    </Row>
                    <br />
                    <Table
                      dataSource={[...tableValues.rows]}
                      columns={tableValues.columns}
                      className="table-height"
                      loading={isLoading}
                      bordered={true}
                      key="6"
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <UnorderedListOutlined /> Summary
                      </span>
                    }
                  >
                    <Row>
                      {/* <Col span={4}>
                       
                       </Col> */}

                      <Col span={24} style={{ display: "flex" , flexDirection:"row", justifyContent:"end"}}>
                        <Button
                          type="primary"
                          style={{
                            float: "right",
                            marginRight: "3px",
                            marginTop: "unset",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            innerSummary("load_data");
                          }}
                        >
                          Default Table
                        </Button>
                        <Button
                          type="dashed"
                          style={{
                            float: "right",
                            marginRight: "3px",
                            marginTop: "unset",
                            fontSize: "14px",
                            boxShadow: "rgb(0 0 0) 0px 0px 5px inset",
                          }}
                          onClick={() => {
                            innerSummary("number_count_summary");
                          }}
                        >
                          Number count
                        </Button>
                        <Button
                          type="dashed"
                          style={{
                            float: "right",
                            marginRight: "3px",
                            marginTop: "unset",
                            fontSize: "14px",
                            boxShadow: "rgb(0 0 0) 0px 0px 5px inset",
                          }}
                          onClick={() => {
                            innerSummary("statistical_summary");
                          }}
                        >
                          Statistical summary
                        </Button>
                        {/* <Button
                          type="dashed"
                          style={{
                            float: "right",
                            marginRight: "3px",
                            marginTop: "unset",
                            fontSize: "14px",
                            boxShadow: "rgb(0 0 0) 0px 0px 5px inset",
                          }}
                          onClick={() => {
                            innerSummary("show_numeric");
                          }}
                        >
                          Show_Numeric
                        </Button> */}
                        <Button
                          type="dashed"
                          style={{
                            float: "right",
                            marginRight: "3px",
                            marginTop: "unset",
                            fontSize: "14px",
                            boxShadow: "rgb(0 0 0) 0px 0px 5px inset",
                          }}
                          onClick={() => {
                            innerSummary("show_categorical");
                          }}
                        >
                          Show Categorical
                        </Button>
                        <Button
                          type="dashed"
                          style={{
                            float: "right",
                            marginRight: "3px",
                            marginTop: "unset",
                            fontSize: "14px",
                            boxShadow: "rgb(0 0 0) 0px 0px 5px inset",
                          }}
                          onClick={() => {
                            innerSummary("column_info");
                          }}
                        >
                          Column Info
                        </Button>
                        {/* <Button type="dashed" style={{float:'right',margin:'0.5%',marginTop:'unset',fontSize:'14px',boxShadow:'rgb(0 0 0) 0px 0px 5px inset'}} onClick={()=>{innerSummary('show_numeric')}}>Show Numerical</Button> */}
                      </Col>
                    </Row>
                    <br />
                    <Table
                      dataSource={[...summaryValues.rows]}
                      columns={summaryValues.columns}
                      className="table-height"
                      loading={isLoading}
                      bordered={true}
                      key="6"
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Content>
        <Footer
          style={{
            padding: 0,
            maxHeight: "max-content",
            backgroundColor: "#171717",
            display: "grid",
          }}
        >
          <h1 style={{ textAlign: "center", margin: "auto", fontSize: "14px" }}>
            All Rights Reserved | © 2022 LivNSense ® Technologies Pvt Ltd
          </h1>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboardpage;
