import React,{useEffect,useState} from 'react';
import {Spin} from 'antd';
import Plot from 'react-plotly.js';


let ModelPlotlyCharts = (props) =>{
    // console.log(props)
    const [chartType,setType] = useState("");
    const [chartXaxis,setx] = useState([]);
    const [chartYaxis,sety] = useState([]);
    const [isLoaded,Loading] = useState(true);
    const [chartWidth,setWidth] = useState();
    useEffect(()=>{
        Loading(true);
        setType(()=>props.type);
        let chartData = props.data;
        let chartRows = [];
        if(props.type == "line" || props.type == "pie"){
            let yvalues = []
            props.yAxis.map((value,index)=>{
                
                chartData.map(values=>{
                    yvalues.push(values[value]);
                });
                
            });
            if(props.type == "line"){
                chartRows.push({type:props.type,y:yvalues,x:props.xaxis});
                let width = document.getElementById('model-chart');
                setWidth(width.offsetWidth)
            }
            else{
                chartRows.push({type:props.type,values:yvalues,labels:props.xaxis,textinfo: "label+percent",
                textposition: "inside",
                hole: .4,
                automargin: false});
                let width = document.getElementById('model-chart-pie');
                setWidth(width.offsetWidth)
            }
        }
        sety(()=>chartRows);
        Loading(false);
        console.log(props.xaxis,props.yAxis,chartRows,chartData)
        
    },[props.type,props.xaxis,props.yAxis,props.data,props.stackmode]);

    return (
        <Spin spinning={isLoaded} style={{height:'100%'}}>
                <Plot
                data={chartYaxis}
                layout={ {
                    plot_bgcolor:"transparent",
                    paper_bgcolor:"transparent",
                    barmode:props.stackmode,
                    autosize:false,
                    showlegend:false,
                    margin: props.type == "line"?{"t": 15, "b": 35, "l": 35, "r": 15}:{"t": 0, "b": 0, "l": 0, "r": 0},
                    // height:300,
                    width:chartWidth-10,
                    yaxis:{
                        tickfont:{
                            family:'Lato',
                            size:12,
                            color:'rgba(255, 255, 255, 0.85)',
                        },
                        gridcolor:'#303030'
                    },
                    xaxis:{
                        tickfont:{
                            family:'Lato',
                            size:12,
                            color:'rgba(255, 255, 255, 0.85)'
                        },
                        gridcolor:'#303030'
                    },
                    legend:{
                        font:{
                            color:'rgba(255, 255, 255, 0.85)',
                            family:'Lato',
                            size:12
                        }
                    }
                    
              } }
              config={{displayModeBar:true}}
            />
        </Spin>
    )
}

export default ModelPlotlyCharts;