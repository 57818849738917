

let fileData = {
    filevalues:'',
    filename:'',
    changedDataTypes:[],
    columnDetails:[],
    columnTypes:[],
    rowDetails:[],
    isLoadCallSet:false,
    apicolumndata:[],
   loadmodelresponse:'',
    loadmodelname:'',

}
export default fileData;