import React from 'react';
import Moment from 'moment';

function Todaydate() {

  const formatDate = Moment().format('DD/MM/YYYY')

  return (
    <div className='date'>
      <p >{formatDate}</p>
    </div>
    
  );
}

export default Todaydate;