import axios from 'axios';


let Apicalls = {
    save: data => axios.post('https://api.dev.edatool.livnsense.com/load_data', data),
    get: (data) => axios.get(`https://api.dev.edatool.livnsense.com/${data}`),
    summary: (data) => axios.get(`https://api.dev.edatool.livnsense.com/${data}`),
    changeData: data => axios.post('https://api.dev.edatool.livnsense.com/conversion_data',data),
    missingData: () => axios.get('https://api.dev.edatool.livnsense.com/missing_data'),
    fillPost:(data)=>axios.post(`https://api.dev.edatool.livnsense.com/fill_na`,data),
    encodingcalls:(url,data)=>axios.post(`https://api.dev.edatool.livnsense.com/${url}`,data),
    encodingcalls2:(url,data)=>axios.post(`https://api.dev.edatool.livnsense.com/${url}`,data),
    normalize: () => axios.get(`https://api.dev.edatool.livnsense.com/normalize`),
    standardize: () => axios.get(`https://api.dev.edatool.livnsense.com/standardize`),
    getDataInfo:()=>axios.get(`https://api.dev.edatool.livnsense.com/get_data_info`),
    linearModel:(data)=>axios.get(`https://api.dev.edatool.livnsense.com/linear_model?y_column=${data.y}&x_columns=${data.x}&test_size=${data.test_size}&rand_state=${data.rand_state}&reg_method=LinearRegression&user_input=Build_model`),
    outlierCall:(url)=>axios.get(`https://api.dev.edatool.livnsense.com/${url}`),
    login: data => axios.post('https://api.dev.edatool.livnsense.com/auth/login?user_id=2', data),
    deleteColumn: (data) => axios.delete(`https://api.dev.edatool.livnsense.com/delete_column_name`,{data:data}),
    saveLinearModel:(data,text)=>axios.get(`https://api.dev.edatool.livnsense.com/linear_model?y_column=${data.y}&x_columns=${data.x}&test_size=${data.test_size}&rand_state=${data.rand_state}&reg_method=LinearRegression&user_input=save_model&project_name=${text}`),
    LoadModel:(text)=>axios.get(`https://api.dev.edatool.livnsense.com/load_linear_model?user_input=load_model&project_name=${text}`),
    LoadPredictModel:(data,text)=>axios.get(`https://api.dev.edatool.livnsense.com/load_linear_model?x_columns=${data.x}&user_input=load_model_predict&project_name=${text}`),
    Savemodellist:()=> axios.get(`https://api.dev.edatool.livnsense.com/save_model_list`),
    pcacalls:(data)=>axios.post(`https://api.dev.edatool.livnsense.com/pca`,data),
    icacalls:(data)=>axios.post(`https://api.dev.edatool.livnsense.com/ica`,data),

}

export default Apicalls;