import React from "react";
import { Layout } from 'antd';
import { Row, Col } from "antd";
// import { Link } from "react-router-dom";
// import sitelogo  from "../logo/lns_logo.png";
import Todaydate from "./Date";

const { Header } = Layout;

let Headerdata = () => {
  
  return(
    
    <Layout>
        <Header>
        <Row gutter={[16, 16]}>
            <Col span={8} ></Col>
            <Col span={6} style={{textAlign:"center", }} >EDA Tools</Col>  
            <Col span={4} ></Col>
            <Col span={6} ><Todaydate/></Col>  
        </Row>
      
      </Header>
 
    </Layout>
  )
}

export default Headerdata