import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    HashRouter,
  } from "react-router-dom";
import Dashboardpage from './Components/Dashboard';
import Homepage from './Components/Homecontent';
import LoginPage from './pages/login/login';
import GeneralEda from './Components/GeneralEDA ';
import ModelbuildingEDA from './Components/Modelbuilding'

let MainLayout = () =>{


    return (
        <HashRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/landing" element={<Homepage />} />
          <Route path="/pre-processing" element={<Dashboardpage/>}/>
          <Route path="/general-eda" element={<GeneralEda/>}/>
          <Route path="/model-building" element={<ModelbuildingEDA/>}/>
        </Routes>
      </HashRouter>

    );
};

export default MainLayout;
